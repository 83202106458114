
import { TreatingDoctor, UpdateTreatingDoctorPayload } from "../../types/TreatingDoctor";
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/treating-doctor";


  export const getTreatingDoctorByIdUserApi = async (id: number):Promise<TreatingDoctor> => {
      try {
        const response = await api.get(`${baseUrl}/user/${id}`, '');
        return response.data;
      } catch (error: any) {
        throw error.response ? error.response.data : new Error('Error de conexión');
      }
  }


  export const updateDigitalSignApi= async (updatePayload : UpdateTreatingDoctorPayload):Promise<TreatingDoctor> => {
    try {
      const response = await api.updateWithFile(`${baseUrl}/digital-sign/${updatePayload.data.id}`,updatePayload.data.file);
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : new Error('Error de conexión');
    }

  }


  export const updateDigitalStampApi= async (updatePayload : UpdateTreatingDoctorPayload):Promise<TreatingDoctor> => {

    try {
      const response = await api.updateWithFile(`${baseUrl}/digital-stamp/${updatePayload.data.id}`,updatePayload.data.file);
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : new Error('Error de conexión');
    }
  }
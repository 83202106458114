import { StudiesActionTypes, StudyActionTypes } from "./studyTypes";


export const getStudies = (id: number): StudyActionTypes => ({
  type: StudiesActionTypes.LIST_STUDIES,
  payload: {
    data:{
      id
    }
  }
}); 

export const openModalStudy = (): StudyActionTypes =>{
  return { type: StudiesActionTypes.OPEN_MODAL}
}

export const closeModalStudy = (): StudyActionTypes =>{
  return { type: StudiesActionTypes.CLOSE_MODAL}
}


export const getFilesStudy = (id: number): StudyActionTypes => ({
  type: StudiesActionTypes.LIST_FILE_STUDIES,
  payload: {
    data:{
      id
    }
  }
}); 


export const getFileImage = (name: string): StudyActionTypes => ({
  type: StudiesActionTypes.GET_IMAGE_FILE_STUDY,
  payload: {
    data:{
      name
    }
  }
}); 

export const getFileLab = (name: string): StudyActionTypes => ({
  type: StudiesActionTypes.GET_LAB_FILE_STUDY,
  payload: {
    data:{
      name
    }
  }
}); 


export const deleteFileImg = (id : number, name: string,  idRequirement: number)=>{
  return { type: StudiesActionTypes.DELETE_FILE_STUDY_IMAGE, payload:{id,name,idRequirement} }
}

export const deleteFileLab = (id : number, name: string, idRequirement: number)=>{
  return { type: StudiesActionTypes.DELETE_FILE_STUDY_LAB, payload:{id,name,idRequirement} }
}

export const updateStatusStudy = (id:number, status: number, idRequirement: number) => {
  return { type: StudiesActionTypes.UPDATE_STATUS_STUDY, payload: { id, status, idRequirement } };
};
// constants
import { AreasActionsType, AreasActionTypes } from "./areaTypes";

export const getAreas = (): AreasActionsType => ({
  type: AreasActionTypes.GET_AREAS,
});

export const saveArea = (
  description: string,
  flagMedicalTeam: boolean
): AreasActionsType => ({
  type: AreasActionTypes.SAVE_AREA,
  payload: {
    data:{
      description,
      flagMedicalTeam,
    }
  },
});

export const updateArea = (
  id: number,
  description: string,
  flagMedicalTeam: boolean
): AreasActionsType => ({
  type: AreasActionTypes.UPDATE_AREA,
  payload: {
    data :{
      id,
      description,
      flagMedicalTeam,
    }
  },
});

export const deleteArea = (id: number): AreasActionsType => ({
  type: AreasActionTypes.DELETE_AREA,
  payload: {
    data:{
      id
    }
  }
});

export const openModalArea = (): AreasActionsType => ({
  type: AreasActionTypes.OPEN_MODAL,
});

export const closeModalArea = (): AreasActionsType => ({
  type: AreasActionTypes.CLOSE_MODAL,
});
import { lazy } from "react";
import { RouteProps } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Root from "./Root";

// Carga diferida de vistas (Lazy loading)
const Login = lazy(() => import("../pages/auth/Login"));
const Logout = lazy(() => import("../pages/auth/Logout"));
const AreasScreen = lazy(() => import("../pages/areas"));
const HospitalsScreen = lazy(() => import("../pages/hospitals"));
const DirectoryScreen = lazy(() => import("../pages/directories"));
const AdminUserScreen = lazy(() => import("../pages/administrators"));
const UsersScreen = lazy(() => import("../pages/users"));
const EnterpriseScreen = lazy(() => import("../pages/enterprises"));
const DashboardScreen = lazy(() => import("../pages/dashboard"));
const ScalesScreen = lazy(() => import("../pages/scales"));

const ImagingRequirementScreen = lazy(() => import("../pages/imaging-studies"));
const LabRequirementScreen = lazy(() => import("../pages/laboratory-studies"));
const ImagingScreen = lazy(() => import("../pages/imaging"));
const LaboratoryScreen = lazy(() => import("../pages/laboratory"));
const ProvidersScreen = lazy(() => import("../pages/providers"));
const FoliosScreen = lazy(() => import("../pages/folios"));
const EquipmentScreen = lazy(() => import("../pages/equipments"));
const CasesScreen = lazy(() => import("../pages/cases"));
const AttendanceScreen = lazy(() => import("../pages/attendance"));
const MedicNoteScreen = lazy(() => import("../pages/medic-notes"));
const FormMedicNoteScreen = lazy(() => import("../pages/medic-notes/FormMedicNote"));
const DigitalSignatureScreen = lazy(() => import("../pages/digital-signature"));
/*const ScheduleScreen = lazy(() => import("../pages/schedule"));
const ProtocolScreen = lazy(() => import("../pages/protocols"));
const UrgencyScreen = lazy(() => import("../pages/protocols/Urgency"));
 */
const Landing = lazy(() => import("../pages/landing"));

// Interfaz para rutas
export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: React.ReactElement;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// Ruta raíz
const rootRoute: RoutesProps = {
  path: "/",
  element: <Root />,
};

// Rutas administrativas
const adminRoutes: RoutesProps = {
  path: "/admin",
  name: "Administración",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "admin/areas",
      name: "Áreas",
      element: <PrivateRoute element={<AreasScreen />} />,
    },
    {
      path: "/admin/hospitales",
      name: "Hospitales",
      roles: ["super_admin"],
      element: <PrivateRoute element={<HospitalsScreen />} />,
    },
    {
      path: "/admin/directorios",
      name: "Directorio",
      roles: ["super_admin"],
      element: <PrivateRoute element={<DirectoryScreen />} />,
    },
  {
      path: "/admin/administradores",
      name: "Usuarios",
      roles: ["super_admin"],
      element: <PrivateRoute element={<AdminUserScreen />} />,
    },
   {
      path: "/admin/usuarios",
      name: "Usuarios",
      roles: ["hospital_admin"],
      element: <PrivateRoute element={<UsersScreen />} />,
    },
    {
      path: "/admin/empresas",
      name: "Empresas",
      roles: ["hospital_admin"],
      element: <PrivateRoute element={<EnterpriseScreen />} />,
    },
    {
      path: "/admin/tablero",
      name: "Tablero",
      roles: ["hospital_admin"],
      element: <PrivateRoute element={<DashboardScreen />} />,
    },
    {
      path: "/admin/archivos/escalas",
      name: "Escalas",
      roles: ["hospital_admin", "hospital_supervisor"],
      element: <PrivateRoute element={<ScalesScreen />} />,
    },
    {
      path: "/admin/requerimientos/imagen",
      name: "Imaging",
      roles: ["hospital_admin", "imaging", "hospital_supervisor"],
      element: <PrivateRoute element={<ImagingScreen />} />,
    },
    {
      path: "/admin/requerimientos/laboratorio",
      name: "Laboratory",
      roles: ["hospital_admin", "imaging", "hospital_supervisor"],
      element: <PrivateRoute element={<LaboratoryScreen />} />,
    },
    {
      path: "/admin/imagen/estudios",
      name: "Imagen",
      roles: ["hospital_admin", "imaging", "hospital_supervisor"],
      element: <PrivateRoute element={<ImagingRequirementScreen />} />,
    },
   
    {
      path: "/admin/laboratorio/estudios",
      name: "Laboratorio",
      roles: ["hospital_admin", "laboratory", "hospital_supervisor"],
      element: <PrivateRoute element={<LabRequirementScreen />} />,
    },
    {
      path: "/admin/proveedores",
      name: "Proveedores",
      roles: ["hospital_admin"],
      element: <PrivateRoute element={<ProvidersScreen />} />,
    },
    {
      path: "/admin/casos",
      name: "Visitas",
      roles: ["hospital_admin", "provider", "hemodynamics_manager", "hospital_supervisor"],
      element: <PrivateRoute element={<CasesScreen />} />,
    },
    {
      path: "/admin/folios",
      name: "Folios",
      roles: ["hospital_admin", "provider", "hemodynamics_manager", "hospital_supervisor"],
      element: <PrivateRoute element={<FoliosScreen />} />,
    },    {
      path: "/admin/dispositivos",
      name: "Dispositivos",
      roles: ["hospital_admin", "provider", "hemodynamics_manager", "hospital_supervisor"],
      element: <PrivateRoute element={<EquipmentScreen />} />,
    },
    {
      path: "/admin/asistencia",
      name: "Asistencia",
      roles: ["hospital_admin", "provider", "hemodynamics_manager"],
      element: <PrivateRoute element={<AttendanceScreen />} />,
    }, 
    {
      path: "/admin/nota-medica",
      name: "Nota Médica",
      roles: ["hospital_treating_doctor"],
      element: <PrivateRoute element={<MedicNoteScreen />} />,
    }, 
    {
      path: "/admin/formulario-nota-medica",
      name: "Nota Médica",
      roles: ["hospital_treating_doctor"],
      element: <PrivateRoute element={<FormMedicNoteScreen />} />,
    },
    {
      path: "/admin/firma-digital",
      name: "Firma",
      roles: ["hospital_treating_doctor"],
      element: <PrivateRoute element={<DigitalSignatureScreen />} />,
    },
   
    /*   {
      path: "/admin/horarios",
      name: "Horarios",
      roles: ["hospital_admin"],
      element: <PrivateRoute element={<ScheduleScreen />} />,
    },  */
    /* /*

   
    {
      path: "/admin/protocolos",
      name: "Protocolos",
      roles: ["super_admin"],
      element: <PrivateRoute element={<ProtocolScreen />} />,
    },

    {
      path: "/admin/urgencia",
      name: "Urgencias",
      element: <PrivateRoute element={<UrgencyScreen />} />,
    },
   ,
     
   
    ,*/
  ],
};

// Rutas de autenticación
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
  }
];

// Rutas públicas
const otherPublicRoutes: RoutesProps[] = [
  {
    path: "/landing",
    name: "Landing",
    element: <Landing />,
  }
];

// Función para aplanar rutas
const flattenRoutes = (routes: RoutesProps[]): RoutesProps[] => {
  let flatRoutes: RoutesProps[] = [];
  routes.forEach((item) => {
    flatRoutes.push(item);
    if (item.children) {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// Todas las rutas
const authProtectedRoutes = [rootRoute, adminRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes,rootRoute];
const authProtectedFlattenRoutes = flattenRoutes(authProtectedRoutes);
const publicProtectedFlattenRoutes = flattenRoutes(publicRoutes);

export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};

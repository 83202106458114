
import { PatientScalesActionsType, PatientScalesActionTypes, PatientScaleState } from "./scalesTypes";


const INIT_STATE : PatientScaleState = {
    loading: false,
    patientScale: [],
    error: null
};


const patientScaleReducer = (state: PatientScaleState = INIT_STATE, action: PatientScalesActionsType): any => {
   
    switch (action.type) {
        case PatientScalesActionTypes.API_RESPONSE_SUCCESS:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case PatientScalesActionTypes.GET_PATIENT_SCALES: {
                    return {
                        ...state, 
                        patientScale : action.payload.data,
                        loading: false,
                        error:''
                    };
                }
                case PatientScalesActionTypes.GET_FILE_SCALE: {
                    return {
                        ...state, 
                        loading: false,
                        error:''
                    };
                }
       
                default:
                    return { ...state };
            }

        case PatientScalesActionTypes.API_RESPONSE_ERROR:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case PatientScalesActionTypes.GET_FILE_SCALE:
                case PatientScalesActionTypes.GET_PATIENT_SCALES: {
                    return {
                        ...state, 
                        loading: false,
                        error: action.payload.data
                    };
                }
            
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default patientScaleReducer;

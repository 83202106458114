// constants
import { PatientsActionsType, PatientsActionTypes, PatientState } from './patientTypes';

const INIT_STATE: PatientState = {
    loading: false,
    patients: [],
    error: '',
    patient: {
        name: '',
        discharged: false
    }
};


const patientReducer = (state: PatientState = INIT_STATE, action: PatientsActionsType): any => {
    switch (action.type) {
        case PatientsActionTypes.API_RESPONSE_SUCCESS:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {

                case PatientsActionTypes.GET_PATIENT_BY_ID: {
                    return {
                        ...state,
                        patients: [],
                        patient: action.payload.data,
                        loading: false,
                        error: ''
                    };
                }

                case PatientsActionTypes.UPDATE_DISCHARGED_PATIENT: {
                    return {
                        ...state,
                        patients: [],
                        loading: false,
                        modal: false,
                        error: ''
                    };
                }

                case PatientsActionTypes.GET_PATIENTS: {
                    return {
                        ...state,
                        patients: action.payload.data,
                        loading: false
                    };
                }
                
                default:
                    return { ...state };
            }

        case PatientsActionTypes.API_RESPONSE_ERROR:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {

                case PatientsActionTypes.GET_PATIENT_BY_ID: {
                    return {
                        ...state,
                        patients: [],
                        loading: false,
                        error: action.payload.data
                    };
                }

                case PatientsActionTypes.UPDATE_DISCHARGED_PATIENT: {
                    return {
                        ...state,
                        patients: [],
                        loading: false,
                        error: action.payload.data
                    };
                }

                case PatientsActionTypes.GET_PATIENTS: {
                    return {
                        ...state,
                        patients: [],
                        loading: false,
                        error: action.payload.data
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default patientReducer;
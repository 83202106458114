import { DoctorsActionsType, DoctorsActionTypes } from "./treatingDoctorTypes";

export const getTreatingDoctorByIdUser = (id: number): DoctorsActionsType => ({
  type: DoctorsActionTypes.GET_TREATING_DOCTOR_BY_USER,
  payload: {
    data :{
      id
    }
  },
});


export const updateDigitalSign = ( id: number, file:  File | undefined | null): DoctorsActionsType => ({
  type: DoctorsActionTypes.UPDATE_DIGITAL_SIGN,
  payload: {
    data :{
      id,
      file
    }
  },
});

export const updateDigitalStamp = (id: number, file:  File | undefined | null): DoctorsActionsType => ({
  type: DoctorsActionTypes.UPDATE_DIGITAL_SIGN,
  payload: {
    data :{
      id,
      file
    }
  },
});





import { EnterprisesActionsType, EnterprisesActionTypes, EnterpriseState } from "./enterpriseTypes";

const INIT_STATE : EnterpriseState = {
    loading: false,
    enterprises: [],
    modal: false,
    url: "",
    error: null,
    deleteStatus: ""
};


const enterpriseReducer = (state: EnterpriseState = INIT_STATE, action: EnterprisesActionsType): any => {
   
    switch (action.type) {
        case EnterprisesActionTypes.API_RESPONSE_SUCCESS:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case EnterprisesActionTypes.CREATE_ENTERPRISE: {
                    return {
                        ...state, 
                        enterprise : action.payload.data,
                        loading: false,
                        modal: false,
                        error:'',
                        url: ''
                    };
                }
                case EnterprisesActionTypes.UPDATE_ENTERPRISE: {
                    return {
                        ...state, 
                        enterprise : action.payload.data,
                        loading: false,
                        error:'',
                        url: ''
                    };
                }
                case EnterprisesActionTypes.DELETE_ENTERPRISE: {
                    return {
                        ...state, 
                        enterprise : action.payload.data,
                        loading: false,
                        modal: false,
                        error:'',
                        url: ''
                    };
                }
                case EnterprisesActionTypes.GET_FILE: {
                    return {
                        ...state, 
                        loading: false,
                        error:'',
                        url: action.payload.data
                    };
                }
                case EnterprisesActionTypes.GET_ALL_ENTERPRISE: {
                    return {
                        ...state, 
                        enterprises : action.payload.data,
                        loading: false,
                        modal: false,
                        error:'',
                        url: ''
                    };
                }
                case EnterprisesActionTypes.OPEN_ENTERPRISE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:true,
                        error:'',
                        url: ''
                    };
                }
                case EnterprisesActionTypes.CLOSE_ENTERPRISE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal: false,
                        error:'',
                        url: ''
                    };
                }
                default:
                    return { ...state };
            }

        case EnterprisesActionTypes.API_RESPONSE_ERROR:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case EnterprisesActionTypes.CREATE_ENTERPRISE: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }
                case EnterprisesActionTypes.UPDATE_ENTERPRISE: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }
                case EnterprisesActionTypes.GET_FILE: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        url: '',
                        error: action.payload.error
                    };
                }
                case EnterprisesActionTypes.GET_ALL_ENTERPRISE: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }

                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default enterpriseReducer;

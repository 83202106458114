import { ClinicalRequirement } from "../../types/ClinicalRequirement";
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/requirements";


export const getImageRequirementsApi = async (): Promise<ClinicalRequirement[]> => {
    try {
      const response = await api.get(`${baseUrl}/all`,{'requirement':'img'});
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : new Error('Error de conexión');
    }
}

export const getLabRequirementsApi = async (): Promise<ClinicalRequirement[]> => {
  try {
    const response = await api.get(`${baseUrl}/all`,{'requirement':'lab'});
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}
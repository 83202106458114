import { AddMedicalNotePayload, MedicalNote, UpdateMedicalNotePayload } from "../../types/MedicalNote";
import { Patient } from "../../types/Patient";
import { ResponseFile } from "../../types/ResponseFile";
import { APICore } from "./apiCore";

export interface ResponseNoteFile{
    url: string;
  }
const api = new APICore();
const baseUrl = "/medic-notes";


export const saveMedicalNoteApi = async (medicalNote: AddMedicalNotePayload):Promise<MedicalNote> => {
    try {
      const response = await api.create(`${baseUrl}`, medicalNote);;
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : new Error('Error de conexión');
    }
}
  
export const updateMedicalNoteApi = async (medicalNote: UpdateMedicalNotePayload):Promise<MedicalNote> => {
    try {
      const response = await api.update(`${baseUrl}`, medicalNote);;
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : new Error('Error de conexión');
    }
}

export const getAllMedicalNotesApi = async ():Promise<MedicalNote[]> => {
    try {
      const response = await api.get(`${baseUrl}/all`, '');
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : new Error('Error de conexión');
    }
  }
  
  export const getPatientsWithoutNoteApi = async () : Promise<Patient[]>=> {
    try {
      const response = await api.get(`${baseUrl}/patients`, '');
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : new Error('Error de conexión');
    }
  }

  export const downloadNoteApi = async (name: string) :Promise<ResponseFile> => {
    try {
      const response = await api.get(`${baseUrl}/file/${name}`,'');
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : new Error('Error de conexión');
    }
  }


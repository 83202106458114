import { PayloadUpdateAttendance } from "../../types/Attendance";
import { ProviderAttendanceActionType, ProviderAttendanceActionTypes } from "./attendanceTypes";


export const updateAttendance = (
  payloadUpdate: PayloadUpdateAttendance
): ProviderAttendanceActionType => ({
  type: ProviderAttendanceActionTypes.UPDATE_ATTENDANCE,
  payload: {data: payloadUpdate}
});

export const getAttendanceByPatient = (id : number)=>{
  return { type: ProviderAttendanceActionTypes.GET_NOT_ATTENDANCE, payload: {data:{id}}}
}

export const getAttendanceByProvider = (id : number)=>{
  return { type: ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PROVIDER, payload: {data:{id}}}
}

export const resetStates = ()=>{
  return { type: ProviderAttendanceActionTypes.RESET_STATE }
}


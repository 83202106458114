import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../redux/store';

// Custom hook for Redux
const useRedux = () => {
    // Use useDispatch with the AppDispatch type
    const dispatch = useDispatch<AppDispatch>();
    // Use useSelector with the RootState type
    const appSelector: TypedUseSelectorHook<RootState> = useSelector;

    // Return both dispatch and selector in an object
    return { dispatch, appSelector };
};

export default useRedux;

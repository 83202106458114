import { Area } from "../../types/Area";

export enum AreasActionTypes {
  API_RESPONSE_SUCCESS = '@@areas/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@areas/API_RESPONSE_ERROR',
  SAVE_AREA = '@@areas/SAVE_AREA',
  UPDATE_AREA = '@@areas/UPDATE_AREA',
  GET_AREAS = '@@areas/GET_AREAS',
  DELETE_AREA = '@@areas/DELETE_AREA',
  OPEN_MODAL = '@@areas/OPEN_MODAL',
  CLOSE_MODAL = '@@areas/CLOSE_MODAL',
}

export interface AreasActionsType {
  type:
  | AreasActionTypes.API_RESPONSE_SUCCESS
  | AreasActionTypes.API_RESPONSE_ERROR
  | AreasActionTypes.UPDATE_AREA
  | AreasActionTypes.GET_AREAS
  | AreasActionTypes.DELETE_AREA
  | AreasActionTypes.SAVE_AREA
  | AreasActionTypes.OPEN_MODAL
  | AreasActionTypes.CLOSE_MODAL
  payload?: {
    actionType?: string;
    data?: Area | Area[] | {};
    error?: string;
  };

}
export interface DeleteAreaAction {
  type: string;
  payload: number;
}

export interface AddAreaPayload {
  data: {
    description: string;
    flagMedicalTeam: string;
  }
}

export interface UpdateAreaPayload {
  data: {
    id: string;
    description: string;
    flagMedicalTeam: string;
  }
}

// common success
export const areaApiResponseSuccess = (
  actionType: string,
  data: Area | {}
): AreasActionsType => ({
  type: AreasActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const areaApiResponseError = (
  actionType: string,
  error: string
): AreasActionsType => ({
  type: AreasActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export interface AreaState {
  loading: boolean;
  areas: Area[];
  modal: boolean;
  error: string | null;
  deleteStatus: string;
}
import { Patient } from "../../types/Patient";

export enum PatientsActionTypes {
  API_RESPONSE_SUCCESS = '@@patients/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@patients/API_RESPONSE_ERROR',
  UPDATE_DISCHARGED_PATIENT  = '@@patients/UPDATE_DISCHARGED_PATIENT',
  GET_PATIENTS = '@@patients/GET_PATIENTS',
  GET_PATIENT_BY_ID = '@@patients/GET_PATIENT_BY_ID'
}

export interface PatientsActionsType {
  type:
  | PatientsActionTypes.API_RESPONSE_SUCCESS
  | PatientsActionTypes.API_RESPONSE_ERROR
  | PatientsActionTypes.UPDATE_DISCHARGED_PATIENT
  | PatientsActionTypes.GET_PATIENTS
  | PatientsActionTypes.GET_PATIENT_BY_ID
  payload?: {
    actionType?: string;
    data?: Patient | Patient[] | {};
    error?: string;
  };
}


// common success
export const patientApiResponseSuccess = (
  actionType: string,
  data: Patient | {}
): PatientsActionsType => ({
  type: PatientsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const patientApiResponseError = (
  actionType: string,
  error: string
): PatientsActionsType => ({
  type: PatientsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export interface PatientState {
  loading: boolean;
  patients: Patient[];
  patient: Patient;
  error: string | null;
}
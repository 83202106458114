
import { Attendance, PayloadUpdateAttendance } from "../../types/Attendance";
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/providers-attendance";

/**
 * Trae las visitas de los proveedores que se les paso Asistencia.
 * @param id 
 * @returns 
 */
export const getAttendanceByProviderApi = async (id: number): Promise<Attendance> => {
  try {
    const response = await api.get(`${baseUrl}/visit-provider/attended/${id}`,'');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
};

export const getNotAttendanceApi = async (id: number): Promise<Attendance> => {
  try {
    const response = await api.get(`${baseUrl}/attended/${id}`,'');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
};

export const updateAttendanceApi = async ( payload: PayloadUpdateAttendance): Promise<Boolean> => {
  try {
    const response = await api.update(`${baseUrl}`,payload);
    if (response.status === 200) {
      return true; 
    } else {
      throw new Error('Error al actualizar el estado.');
    }
  } catch (error: any) {
    if (error.response) {
      const errorMessage = error.response?.data?.message || 'Error de servidor';
      throw new Error(errorMessage);
    } else {
      throw new Error('Error de conexión');
    }
  }
}



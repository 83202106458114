import { MedicalNotesActionType, MedicalNotesActionTypes } from "./noteTypes";

export const saveMedicalNote = (
  treatingDoctor: number,
  description: string,
  patient: number,
  treatment?: number,
): MedicalNotesActionType => ({
  type: MedicalNotesActionTypes.SAVE_NOTE,
  payload: {
    data :{
      treatingDoctor,
      description,
      patient,
      treatment
    }
  },
});

export const getMedicalNotes = ()=>{
  return { type: MedicalNotesActionTypes.GET_NOTES }
}

export const getPatientsWithout = ()=>{
  return { type: MedicalNotesActionTypes.GET_PATIENTS }
}


export const downloadNote = (name: string) => {
  return { type: MedicalNotesActionTypes.DOWNLOAD_NOTE, payload: { name } };
};

export const openModalNote = () =>{
  return { type: MedicalNotesActionTypes.OPEN_NOTE_MODAL}
}
export const closeModalNote = () =>{
  return { type: MedicalNotesActionTypes.CLOSE_NOTE_MODAL}
}
import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";


// helpers
import {
  saveDirectoryApi,
  updateDirectoryApi,
  getDirectoriesApi,
  deleteDirectoryApi
} from "../../helpers";
import { AddHospitalDirectoryPayload, directoryApiResponseError, directoryApiResponseSuccess, HospitalDirectoryActionType, UpdateHospitalDirectoryPayload } from "./directoryTypes";
import { HospitalDirectory } from "../../types/HospitalDirectory";


/**
 * Get Directorys
 * @param {*} payload - 
 */
function* getDirectories(): SagaIterator {
  try {
    const directories: HospitalDirectory[] = yield call(getDirectoriesApi );
    yield put(directoryApiResponseSuccess(HospitalDirectoryActionType.GET_DIRECTORIES, directories));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(directoryApiResponseError(HospitalDirectoryActionType.GET_DIRECTORIES, errorMessage));
  }
}

/**
 * Create Directory
 * @param {*} payload - 
 */
function* createDirectory(action: { type: string; payload: AddHospitalDirectoryPayload }): SagaIterator {
  try {
    const directory = yield call(saveDirectoryApi, action.payload);
    yield put(directoryApiResponseSuccess(HospitalDirectoryActionType.SAVE_DIRECTORY, directory));
    yield call(getDirectories);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(directoryApiResponseError(HospitalDirectoryActionType.SAVE_DIRECTORY, errorMessage));
  }
}


/**
 * Update Directory
 * @param {*} payload - 
 */

function* updateDirectory(action: { type: string; payload: UpdateHospitalDirectoryPayload }): SagaIterator {
  try {
    const directory = yield call(updateDirectoryApi, action.payload);
    yield put(directoryApiResponseSuccess(HospitalDirectoryActionType.UPDATE_DIRECTORY, directory));
    yield call(getDirectories);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(directoryApiResponseError(HospitalDirectoryActionType.UPDATE_DIRECTORY, errorMessage));
  }
}

/**
 * Delete
 * @param {*} payload - 
 */
function* deleteDirectory(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {   
    yield call(deleteDirectoryApi, action.payload.data.id);
    yield put(directoryApiResponseSuccess(HospitalDirectoryActionType.DELETE_DIRECTORY, action.payload.data.id));
    yield call(getDirectories);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(directoryApiResponseError(HospitalDirectoryActionType.DELETE_DIRECTORY, errorMessage));
  }
}

function* openModalDirectory(): SagaIterator{
  yield put(directoryApiResponseSuccess(HospitalDirectoryActionType.OPEN_MODAL,""));
}

function* closeModalDirectory(): SagaIterator{
  yield put(directoryApiResponseSuccess(HospitalDirectoryActionType.CLOSE_MODAL,""));

}

export function* watchOpenModalDirectory() {
  yield takeEvery(HospitalDirectoryActionType.OPEN_MODAL, openModalDirectory);
}
export function* watchCreateDirectory() {
  yield takeEvery(HospitalDirectoryActionType.SAVE_DIRECTORY, createDirectory);
}

export function* watchGetDirectories() {
  yield takeEvery(HospitalDirectoryActionType.GET_DIRECTORIES, getDirectories);
}

export function* watchCloseModalDirectory() {
  yield takeEvery(HospitalDirectoryActionType.CLOSE_MODAL,closeModalDirectory);
}

export function* watchUpdateDirectory() {
  yield takeEvery(HospitalDirectoryActionType.UPDATE_DIRECTORY, updateDirectory);
}
export function* watchDeleteDirectory() {
  yield takeEvery(HospitalDirectoryActionType.DELETE_DIRECTORY, deleteDirectory);
}

function* directorySaga() {
  yield all([
    fork(watchOpenModalDirectory),
    fork(watchCloseModalDirectory),
    fork(watchCreateDirectory),
    fork(watchUpdateDirectory),
    fork(watchGetDirectories),
  ]);
}

export default directorySaga;

import { EnterprisesActionsType, EnterprisesActionTypes } from "./enterpriseTypes";


export const getEnterprises = (): EnterprisesActionsType => ({
  type: EnterprisesActionTypes.GET_ALL_ENTERPRISE,
});

export const saveEnterpriseWithFile = (
  name: string,
  file:  File | null ,
): EnterprisesActionsType => ({
    type: EnterprisesActionTypes.CREATE_ENTERPRISE,
    payload: {
      data: {
        name,
        file,
      }
    },
  });

export const updateEnterpriseWithFile = (
    id: number,
    name: string,
    file:  File | null ,
  ): EnterprisesActionsType => ({
      type: EnterprisesActionTypes.UPDATE_ENTERPRISE,
      payload: {
        data : {
          id,
          name,
          file,
        }
      },
    });
 

export const deleteEnterprise = (id: number): EnterprisesActionsType => ({
      type: EnterprisesActionTypes.DELETE_ENTERPRISE,
      payload: {
        data:{
          id
        }
      }
  });    


export const getFileEnterprises = (name : string)=>{
  return { type: EnterprisesActionTypes.GET_FILE ,payload:{name}}
} 

export const openModalEnterprise = ():EnterprisesActionsType =>{
  return { type: EnterprisesActionTypes.OPEN_ENTERPRISE_MODAL}
}
export const closeModalEnterprise = ():EnterprisesActionsType =>{
  return { type: EnterprisesActionTypes.CLOSE_ENTERPRISE_MODAL}
}
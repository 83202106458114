
import { AddEquipmentPayload, Equipment, UpdateEquipmentPayload } from "../../types/Equipment";
import { ResponseFile } from "../../types/ResponseFile";
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/equipments";

export const getEquipmentsByIdOrderApi = async (id: number): Promise<Equipment[]> => {
  try {
    const response = await api.get(`${baseUrl}/order-number/${id}`, '');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const getFileEquipmentApi = async (name: string):Promise<ResponseFile> => {
  try {
    const response = await api.get(`${baseUrl}/files/${name}`, '');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}


export const saveEquipmentApi = async (equipment: AddEquipmentPayload):Promise<Equipment> => {
  try {
    const response = await api.create(`${baseUrl}`, equipment);;
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const updateEquipmentApi = async (equipment: UpdateEquipmentPayload):Promise<Equipment> => {
  try {
    const response = await api.update(`${baseUrl}`, equipment);;
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const deleteEquipmentApi = async ( id: number): Promise<Boolean> => {
  try {
    const response = await api.delete(`${baseUrl}/${id}`);
    if (response.status === 200) {
      return true; 
    } else {
      throw new Error('Error al eliminar el equipo');
    }
  } catch (error: any) {
    if (error.response) {
      const errorMessage = error.response?.data?.message || 'Error de servidor';
      throw new Error(errorMessage);
    } else {
      throw new Error('Error de conexión');
    }
  }
}


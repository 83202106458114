import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";


import { Patient } from "../../types/Patient";
import { getAllPatientsApi, getPatientsByIdApi, updateDischargedPatientApi } from "../../helpers/api/patients";
import { patientApiResponseError, patientApiResponseSuccess, PatientsActionTypes } from "./patientTypes";


/**
 * Get  Patients
 * @param {*} payload - 
 */
function* getPatients(): SagaIterator {
  try {
    const patients: Patient[] = yield call(getAllPatientsApi);
    yield put(patientApiResponseSuccess(PatientsActionTypes.GET_PATIENTS, patients));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(patientApiResponseError(PatientsActionTypes.GET_PATIENTS, errorMessage));
  }
}


/**
 * Get by ID  Patients
 * @param {*} payload - 
 */
function* getPatientById(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {
    const patient: Patient = yield call(getPatientsByIdApi, action.payload.data.id);
    yield put(patientApiResponseSuccess(PatientsActionTypes.GET_PATIENT_BY_ID, patient));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(patientApiResponseError(PatientsActionTypes.GET_PATIENT_BY_ID, errorMessage));
  }
}


/**
 * UPDATE_DISCHARGED_PATIENT
 * @param {*} payload - 
 */
function* updateDischargedPatient(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {   
    yield call(updateDischargedPatientApi, action.payload.data.id);
    yield put(patientApiResponseSuccess(PatientsActionTypes.UPDATE_DISCHARGED_PATIENT, action.payload.data.id));
    yield call(getPatients);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(patientApiResponseError(PatientsActionTypes.UPDATE_DISCHARGED_PATIENT, errorMessage));
  }
}


export function* watchGetPatients() {
  yield takeEvery(PatientsActionTypes.GET_PATIENTS, getPatients);
}
export function* watchGetPatientById() {
  yield takeEvery(PatientsActionTypes.GET_PATIENT_BY_ID, getPatientById);
}

export function* watchUpdateDischargedPatient() {
  yield takeEvery(PatientsActionTypes.UPDATE_DISCHARGED_PATIENT, updateDischargedPatient);
}


function* patientSaga() {
  yield all([
    fork(watchUpdateDischargedPatient),
    fork(watchGetPatients),
    fork(watchGetPatientById)
  ]);
}

export default patientSaga;
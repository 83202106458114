import { Equipment } from "../../types/Equipment";

export enum EquipmentsActionTypes {
    API_RESPONSE_SUCCESS = '@@equipments/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@equipments/API_RESPONSE_ERROR',
    GET_EQUIPMENT_BY_ID = '@@equipments/GET_EQUIPMENT_BY_ID',
    UPDATE_EQUIPMENT = '@@equipments/UPDATE_EQUIPMENT',
    DELETE_EQUIPMENT = '@@equipments/DELETE_EQUIPMENT',
    GET_FILE = '@@equipments/GET_FILE',
    CREATE_EQUIPMENT = '@@equipments/CREATE_EQUIPMENT',
    OPEN_EQUIPMENT_MODAL = '@@equipments/OPEN_EQUIPMENT_MODAL',
    CLOSE_EQUIPMENT_MODAL = '@@equipments/CLOSE_EQUIPMENT_MODAL',
}



export interface EquipmentsActionsType {
    type:
      | EquipmentsActionTypes.API_RESPONSE_SUCCESS
      | EquipmentsActionTypes.API_RESPONSE_ERROR
      | EquipmentsActionTypes.CREATE_EQUIPMENT
      | EquipmentsActionTypes.GET_EQUIPMENT_BY_ID
      | EquipmentsActionTypes.GET_FILE
      | EquipmentsActionTypes.OPEN_EQUIPMENT_MODAL
      | EquipmentsActionTypes.CLOSE_EQUIPMENT_MODAL
      | EquipmentsActionTypes.UPDATE_EQUIPMENT
      | EquipmentsActionTypes.DELETE_EQUIPMENT 
      payload?: {
        actionType?: string;
        data?: Equipment | Equipment[] | {};
        error?: string;
      };
  }
  
  interface EquipmentData {
    orderNumber: string;
    brand :  string;
    model:  string;
    quantity : number;
    characteristic : string;
    price : number;
    total :number;
    file:  File | null;
  }
  
  
  
  // common success
  export const equipmentApiResponseSuccess = (
    actionType: string,
    data: EquipmentData | {}
  ): EquipmentsActionsType => ({
    type: EquipmentsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
  });
  // common error
  export const equipmentApiResponseError = (
    actionType: string,
    error: string
  ): EquipmentsActionsType => ({
    type: EquipmentsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
  });
  
  
 export interface EquipmentState {
    loading: boolean;
    path : string;
    equipments: Equipment[];
    error: '';
    modal: boolean;
}
  


import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  saveUserApi,
  getUsersApi,
  deleteUserApi,
  updateUserApi
} from "../../helpers";
import { AddUserPayload, UpdateUserPayload, UserActionTypes, userApiResponseError, userApiResponseSuccess } from "./userTypes";
import { User } from "../../types/User";

/**
 * Get Users
 * @param {*} payload - 
 */

function* getUsers(): SagaIterator {
  try {
    const users: User[]  = yield call(getUsersApi);
    yield put(userApiResponseSuccess(UserActionTypes.GET_USERS, users));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(userApiResponseError(UserActionTypes.GET_USERS, errorMessage));
  }
}


/**
 * Create User
 * @param {*} payload - 
 */
function* createUser(action: { type: string; payload: AddUserPayload }): SagaIterator {
  try {
    const user = yield call(saveUserApi, action.payload);
    yield put(userApiResponseSuccess(UserActionTypes.SAVE_USER, user));
    yield call(getUsers);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(userApiResponseError(UserActionTypes.SAVE_USER, errorMessage));
  }
}



/**
 * Update User
 * @param {*} payload - 
 */
function* updateUser(action: { type: string; payload: UpdateUserPayload }): SagaIterator {
  try {
    const user = yield call(updateUserApi, action.payload);
    yield put(userApiResponseSuccess(UserActionTypes.UPDATE_USER, user));
    yield call(getUsers);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(userApiResponseError(UserActionTypes.UPDATE_USER, errorMessage));
  }
}


/**
 * Delete
 * @param {*} payload - 
 */
function* deleteUser(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {   
    yield call(deleteUserApi, action.payload.data.id);
    yield put(userApiResponseSuccess(UserActionTypes.DELETE_USER, action.payload.data.id));
    yield call(getUsers);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(userApiResponseError(UserActionTypes.DELETE_USER, errorMessage));
  }
}








function* openModalUser({
  type,
}:any): SagaIterator{
    yield put(userApiResponseSuccess(UserActionTypes.OPEN_MODAL,""));
 
}

function* closeModalUser({
  type,
}:any): SagaIterator{
    yield put(userApiResponseSuccess(UserActionTypes.CLOSE_MODAL,""));
 
}
export function* watchDeletelUser() {
  yield takeEvery(UserActionTypes.CLOSE_MODAL,deleteUser);
}

export function* watchCloseModalUser() {
  yield takeEvery(UserActionTypes.CLOSE_MODAL,closeModalUser);
}
export function* watchUpdateUser() {
  yield takeEvery(UserActionTypes.UPDATE_USER, updateUser);
}

export function* watchGetUsers() {
  yield takeEvery(UserActionTypes.GET_USERS, getUsers);
}
export function* watchOpenModalUser() {
  yield takeEvery(UserActionTypes.OPEN_MODAL,openModalUser);
}
export function* watchCreateUser() {
  yield takeEvery(UserActionTypes.SAVE_USER, createUser);
}



function* userSaga() {
  yield all([
    fork(watchCloseModalUser),
    fork(watchOpenModalUser),
    fork(watchCreateUser),
    fork(watchUpdateUser),
    fork(watchGetUsers)
  ]);
}

export default userSaga;

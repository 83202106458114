import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { SaveFolioPayload } from "../../types/Folio";
import { folioApiResponseError, folioApiResponseSuccess, FoliosActionTypes } from "./folioTypes";
import { approveFolioApi, closeUploadApi, getFileFolioApi, getFoliosByIdVisitApi, rejectFolioApi, saveFolioApi } from "../../helpers";



/**
 * Create Folio
 * @param {*} payload - 
 */
function* createFolio(action: { type: string; payload: SaveFolioPayload }): SagaIterator {
  try {
    const folio = yield call(saveFolioApi, action.payload);
    yield put(folioApiResponseSuccess(FoliosActionTypes.CREATE_FOLIO, folio));
    const folioAction = { type: FoliosActionTypes.GET_FOLIO_BY_ID, payload: { data: { id: action.payload.attendance?action.payload.attendance: 0 } } };
    yield call(getFolios,folioAction);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(folioApiResponseError(FoliosActionTypes.CREATE_FOLIO, errorMessage));
  }
}



/**
 * Get Folios
 * @param {*} payload - 
 */
function* getFolios(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {   
    yield call(getFoliosByIdVisitApi, action.payload.data.id);
    yield put(folioApiResponseSuccess(FoliosActionTypes.GET_FOLIO_BY_ID, action.payload.data.id));

  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(folioApiResponseError(FoliosActionTypes.GET_FOLIO_BY_ID, errorMessage));
  }
}


/**
 * Get Folios
 * @param {*} payload - 
 */
function* getFileFolio(action: { type: string; payload: { data : { name: string } } }): SagaIterator {
  try {   
    const response = yield call(getFileFolioApi, action.payload.data.name);
    var fileLink = document.createElement('a');
    fileLink.href = response.url;
    fileLink.setAttribute('download', action.payload.data.name);
    document.body.appendChild(fileLink);
    fileLink.click();
    yield put(folioApiResponseSuccess(FoliosActionTypes.GET_FILE, action.payload.data));

  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(folioApiResponseError(FoliosActionTypes.GET_FILE, errorMessage));
  }
}



function* approveFolio(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {   
    yield call(approveFolioApi, action.payload.data.id);
    yield put(folioApiResponseSuccess(FoliosActionTypes.APPROVE_FOLIO, action.payload.data.id));
    const folioAction = { type: FoliosActionTypes.GET_FOLIO_BY_ID, payload: { data: { id: action.payload.data.id } } };
    yield call(getFolios,folioAction);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(folioApiResponseError(FoliosActionTypes.APPROVE_FOLIO, errorMessage));
  }
}

function* rejectFolio(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {   
    yield call(rejectFolioApi, action.payload.data.id);
    yield put(folioApiResponseSuccess(FoliosActionTypes.REJECT_FOLIO, action.payload.data.id));
    const folioAction = { type: FoliosActionTypes.GET_FOLIO_BY_ID, payload: { data: { id: action.payload.data.id } } };
    yield call(getFolios,folioAction);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(folioApiResponseError(FoliosActionTypes.REJECT_FOLIO, errorMessage));
  }
}

function* closeUploadFolio(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {   
    yield call(closeUploadApi, action.payload.data.id);
    yield put(folioApiResponseSuccess(FoliosActionTypes.CLOSE_UPLOAD_FOLIO, action.payload.data.id));
    const folioAction = { type: FoliosActionTypes.GET_FOLIO_BY_ID, payload: { data: { id: action.payload.data.id } } };
    yield call(getFolios,folioAction);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(folioApiResponseError(FoliosActionTypes.CLOSE_UPLOAD_FOLIO, errorMessage));
  }
}

function* closeModalFolio({
  type,
}:any): SagaIterator{
    yield put(folioApiResponseSuccess(FoliosActionTypes.CLOSE_FOLIO_MODAL,""));
 
}

function* openModalFolio({
  type,
}:any): SagaIterator{
    yield put(folioApiResponseSuccess(FoliosActionTypes.OPEN_FOLIO_MODAL,""));
 
}



export function* watchOpenModalFolio() {
  yield takeEvery(FoliosActionTypes.OPEN_FOLIO_MODAL,openModalFolio);
}

export function* watchGetFolios() {
  yield takeEvery(FoliosActionTypes.GET_FILE, getFolios);
}

export function* watchCloseModalFolio() {
  yield takeEvery(FoliosActionTypes.CLOSE_FOLIO_MODAL,closeModalFolio);
}
export function* watchCreateFolio() {
  yield takeEvery(FoliosActionTypes.CREATE_FOLIO, createFolio);
}

export function* watchGetFileFolios() {
  yield takeEvery(FoliosActionTypes.GET_FILE, getFileFolio);
}
export function* watchApproveFolio() {
  yield takeEvery(FoliosActionTypes.APPROVE_FOLIO, approveFolio);
}

export function* watchRejectFolio() {
  yield takeEvery(FoliosActionTypes.REJECT_FOLIO, rejectFolio);
}

export function* watchCloseUploadFolio() {
  yield takeEvery(FoliosActionTypes.CLOSE_UPLOAD_FOLIO, closeUploadFolio);
}




function* folioSaga() {
  yield all([
    fork(watchGetFolios),
    fork(watchCloseModalFolio),
    fork(watchOpenModalFolio),
    fork(watchCreateFolio),
    fork(watchGetFileFolios),
    fork(watchApproveFolio),
    fork(watchRejectFolio),
    fork(watchCloseUploadFolio)
  ]);
}

export default folioSaga;

import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/requirements";

export const getLabRequirements = async () => {
  return await api.get(`${baseUrl}/all`,{'requirement':'lab'});
}


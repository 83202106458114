import { PatientScale } from "../../types/PatientScale";
import { ResponseFile } from "../../types/ResponseFile";
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/scales";


export const getAllScalesApi = async (idPatient: number) : Promise<PatientScale[]> => {
  try {

    const response = await api.get(`${baseUrl}/all/${idPatient}`,'');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const getFileScaleApi = async (name: string) :Promise<ResponseFile> => {
  try {
    const response = await api.get(`${baseUrl}/files/${name}`,'');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}



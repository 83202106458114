import { FoliosActionsType, FoliosActionTypes } from "./folioTypes";

export const saveFolioWithFile = (
  orderNumber: string,
  attendance: number
): FoliosActionsType => ({
  type: FoliosActionTypes.CREATE_FOLIO,
  payload: {
    data:{
      orderNumber,
      attendance,
    }

  },
});

export const approveFolio = (
  id: number
): FoliosActionsType => ({
  type: FoliosActionTypes.APPROVE_FOLIO,
  payload: {
    data:{
      id
    }
  },
});

export const rejectFolio = (
  id: number
): FoliosActionsType => ({
  type: FoliosActionTypes.REJECT_FOLIO,
  payload: {
    data : {
      id
    }
    
  },
});

export const closeUploadFolio = (
  id: number
): FoliosActionsType => ({
  type: FoliosActionTypes.CLOSE_UPLOAD_FOLIO,
  payload: {
    data :{
      id
    }
  },
});

export const getFolios = (id: number) => {
  return { type: FoliosActionTypes.GET_FOLIO_BY_ID, payload: { id } };
};

export const getFileFolios = (name: string) => {
  return { type: FoliosActionTypes.GET_FILE, payload: { name } };
};

export const openModalFolio = () => {
  return { type: FoliosActionTypes.OPEN_FOLIO_MODAL };
};
export const closeModalFolio = () => {
  return { type: FoliosActionTypes.CLOSE_FOLIO_MODAL };
};



import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { AddMedicalNotePayload, MedicalNote, UpdateMedicalNotePayload } from "../../types/MedicalNote";
import { medicalNoteApiResponseError, medicalNoteApiResponseSuccess, MedicalNotesActionTypes } from "./noteTypes";
import { downloadNoteApi, getAllMedicalNotesApi, getPatientsWithoutNoteApi, saveMedicalNoteApi, updateMedicalNoteApi } from "../../helpers";
import { Patient } from "../../types/Patient";




/**
 * Create Directory
 * @param {*} payload - 
 */
function* createMedicalNote(action: { type: string; payload: AddMedicalNotePayload }): SagaIterator {
  try {
    const medicalNote = yield call(saveMedicalNoteApi, action.payload);
    yield put(medicalNoteApiResponseSuccess(MedicalNotesActionTypes.SAVE_NOTE, medicalNote));
    yield call(getMedicalNotes);

  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(medicalNoteApiResponseError(MedicalNotesActionTypes.SAVE_NOTE, errorMessage));
  }
}

function* updateMedicalNote(action: { type: string; payload: UpdateMedicalNotePayload }): SagaIterator {
  try {
    const medicalNote = yield call(updateMedicalNoteApi, action.payload);
    yield put(medicalNoteApiResponseSuccess(MedicalNotesActionTypes.UPDATE_NOTE, medicalNote));
    yield call(getMedicalNotes);

  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(medicalNoteApiResponseError(MedicalNotesActionTypes.UPDATE_NOTE, errorMessage));
  }
}

function* getMedicalNotes(): SagaIterator {
  try {
    const medicalNotes: MedicalNote[] = yield call(getAllMedicalNotesApi );
    yield put(medicalNoteApiResponseSuccess(MedicalNotesActionTypes.GET_NOTES, medicalNotes));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(medicalNoteApiResponseError(MedicalNotesActionTypes.GET_NOTES, errorMessage));
  }
}


function* getPatientsWithoutNote(): SagaIterator {
  try {
    const patients: Patient[] = yield call(getPatientsWithoutNoteApi );
    yield put(medicalNoteApiResponseSuccess(MedicalNotesActionTypes.GET_PATIENTS, patients));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(medicalNoteApiResponseError(MedicalNotesActionTypes.GET_PATIENTS, errorMessage));
  }
}

function* getNoteFile(action: { type: string; payload: { data : { name: string } } }): SagaIterator {
  try {   
    const response = yield call(downloadNoteApi, action.payload.data.name);
    var fileLink = document.createElement('a');
    fileLink.href = response.url;
    fileLink.setAttribute('download', action.payload.data.name);
    document.body.appendChild(fileLink);
    fileLink.click();
    yield put(medicalNoteApiResponseSuccess(MedicalNotesActionTypes.DOWNLOAD_NOTE, action.payload.data));

  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(medicalNoteApiResponseError(MedicalNotesActionTypes.DOWNLOAD_NOTE, errorMessage));
  }
}



function* openNoteModal(): SagaIterator{
    yield put(medicalNoteApiResponseSuccess(MedicalNotesActionTypes.OPEN_NOTE_MODAL,""));
 
}

function* closeNoteModal(): SagaIterator{
    yield put(medicalNoteApiResponseSuccess(MedicalNotesActionTypes.CLOSE_NOTE_MODAL,""));
 
}



export function* watchGetNoteFile() {
  yield takeEvery(MedicalNotesActionTypes.DOWNLOAD_NOTE, getNoteFile);
}
export function* watchCreateMedicalNote() {
  yield takeEvery(MedicalNotesActionTypes.SAVE_NOTE, createMedicalNote);
}

export function* watchUpdateMedicalNote() {
  yield takeEvery(MedicalNotesActionTypes.UPDATE_NOTE, updateMedicalNote);
}

export function* watchGetMedicalNotes() {
  yield takeEvery(MedicalNotesActionTypes.GET_NOTES, getMedicalNotes);
}

export function* watchOpenNoteModal() {
  yield takeEvery(MedicalNotesActionTypes.OPEN_NOTE_MODAL,openNoteModal);
}

export function* watchCloseNoteModal() {
  yield takeEvery(MedicalNotesActionTypes.CLOSE_NOTE_MODAL,closeNoteModal);
}

export function* watchGetPatients() {
  yield takeEvery(MedicalNotesActionTypes.GET_PATIENTS, getPatientsWithoutNote);
}


function* medicalNoteSaga() {
  yield all([
    fork(watchCreateMedicalNote),
    fork(watchGetMedicalNotes),
    fork(watchOpenNoteModal),
    fork(watchCloseNoteModal),
    fork(watchGetPatients),
    fork(watchUpdateMedicalNote),
    fork(watchGetNoteFile)
  ]);
}

export default medicalNoteSaga;

import { UserActionType, UserActionTypes, UserState } from "./userTypes";

enum STATUS {
  SUCCESS = "SUCCESS",
  FAIL = "FAIL"
}

const INIT_STATE: UserState = {
  loading: false,
  users: [],
  user: {},
  modal: false,
  error: '',
  deleteStatus: ''
};



// Reducer
const userReducer = (state: UserState = INIT_STATE, action: UserActionType): UserState => {
  switch (action.type) {
    case UserActionTypes.API_RESPONSE_SUCCESS:
      if (!action.payload) return { ...state };
      switch (action.payload.actionType) {
        case UserActionTypes.UPDATE_USER:
        case UserActionTypes.SAVE_USER: {
          return {
            ...state,
            users: [],
            loading: false,
            modal: false,
            error: '',
            deleteStatus: ''
          };
        }
        case UserActionTypes.GET_USERS: {
          return {
            ...state,
            users: Array.isArray(action.payload.data) ? action.payload.data : [],
            loading: false,
            modal: false,
            error: '',
            deleteStatus: ''
          };
        }

        case UserActionTypes.DELETE_USER: {
          return {
            ...state,
            users: [],
            loading: false,
            modal: false,
            error: '',
            deleteStatus: STATUS.SUCCESS
          };
        }

        case UserActionTypes.OPEN_MODAL:
          return {
            ...state,
            modal: true,
            loading: false,
            error: '',
            deleteStatus: ''
          };

        case UserActionTypes.CLOSE_MODAL:
          return {
            ...state,
            modal: false,
            loading: false,
            error: '',
            deleteStatus: ''
          };

        default:
          return state; // Covered all action types
      }

    case UserActionTypes.API_RESPONSE_ERROR:
      if (!action.payload) return { ...state };
      switch (action.payload.actionType) {
        case UserActionTypes.SAVE_USER:
        case UserActionTypes.UPDATE_USER:
        case UserActionTypes.GET_USERS: {
          return {
            ...state,
            modal: false,
            loading: false,
            error: action.payload.error,
            deleteStatus: ''
          };
        }
        case UserActionTypes.DELETE_USER: {
          return {
            ...state,
            loading: false,
            modal: false,
            error: action.payload.error,
            deleteStatus: STATUS.FAIL
          };
        }
        default:
          return state; 
      }


    default:
      return state; 
  }
};

export default userReducer;
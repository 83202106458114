// constants
import { AuthActionTypes } from "./constants";

export interface AuthActionType {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET;
  payload?: {
    actionType?: string;
    data?: UserData | {} | undefined;
    error?: string;
  };
}

interface UserData {
  id?: number| undefined;
  email: string;
  name?: string| undefined;
  surname?: string| undefined;
  lastName?: string| undefined;
  optionalEmail?: string| undefined;
  localPhone?: string| undefined;
  localExtension?: number| undefined;
  directPhone?: string| undefined;
  cellPhone?: string| undefined;
  avatarLink?: string| undefined;
  creationDate?: string| undefined;
  updateDate?: string| undefined;
  deleteDate?: string| undefined;
  password?: string| undefined;
}

// common success
export const authApiResponseSuccess = (
  actionType: string,
  data: UserData | {}
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const authApiResponseError = (
  actionType: string,
  error: string
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const loginUser = (email: string, password?: string): AuthActionType => ({
  type: AuthActionTypes.LOGIN_USER,
  payload: { data : {email, password }},
});

export const logoutUser = (): AuthActionType => ({
  type: AuthActionTypes.LOGOUT_USER,
  payload: {},
});

export const resetAuth = (): AuthActionType => ({
  type: AuthActionTypes.RESET,
  payload: {},
});


import { StudyFile, UploadFileStudy } from "../../types/ClinicalRequirementFile";
import { ResponseFile } from "../../types/ResponseFile";
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/requirement-files";



export const uploadStudyFilesApi = async (upload: UploadFileStudy): Promise<ResponseFile> => {
  try {
    const response = await api.create(`${baseUrl}/videos/`,upload);
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const getFilesByStudyApi = async (id: number): Promise<StudyFile> => {
  try {
    const response = await api.get(`${baseUrl}/study/${id}`,'');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}


export const uploadFileImagingApi= async(params: { 
  study: number;
  file:  File | null | undefined;
}) =>{
  return api.createWithFile(`${baseUrl}/image/`, params);
}

export const uploadFileLaboratoryApi= async(params: { 
  study: number;
  file:  File | null | undefined;
}) =>{
  return api.createWithFile(`${baseUrl}/lab/`, params);
}

export const deleteFileImageApi = async (id: number, name: string) => {
  return await api.delete(`${baseUrl}/files/image/${id}/${name}`);
}

export const deleteFileLabApi = async (id: number, name: string) => {
  return await api.delete(`${baseUrl}/files/lab/${id}/${name}`);
}


export const getFileImgApi = async (name: string):Promise<ResponseFile> => {
  try {
    const response = await api.get(`${baseUrl}/files/image/${name}`, '');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}


export const getFileLabApi = async (name: string):Promise<ResponseFile> => {
  try {
    const response = await api.get(`${baseUrl}/files/lab/${name}`, '');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}
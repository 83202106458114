import { Suspense, ReactNode } from 'react';
import { Outlet } from 'react-router-dom'; // Importar Outlet

const Loading = () => <div className="loading"></div>;

interface DefaultLayoutProps {
    layout: {
        layoutType: string | null,
        layoutWidth: string | null,
        menuPosition: string | null,
        leftSideBarTheme: string | null,
        leftSideBarType: string | null,
        showSidebarUserInfo: boolean,
        topbarTheme: string | null,
        isOpenRightSideBar: boolean,
    };
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ layout }) => {
    return (
        <Suspense fallback={<Loading />}>
            {/* Utiliza Outlet para renderizar rutas anidadas */}
            <Outlet />
        </Suspense>
    );
};

export default DefaultLayout;


import { Study } from "../../types/Study";
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/studies";

interface PayloadUpdateStatusStudy{
  id: number; status: number
}

export const getStudiesApi = async (id: number): Promise<Study[]> => {
  try {
    const response = await api.get(`${baseUrl}/requirement/${id}`,'');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}


export const updateStatusStudyApi = async ( payload: PayloadUpdateStatusStudy): Promise<Boolean> => {
  try {
    const response = await api.update(`${baseUrl}/study/${payload.id}/status/${payload.status}/`,'');;
    if (response.status === 200) {
      return true; 
    } else {
      throw new Error('Error al actualizar el estado.');
    }
  } catch (error: any) {
    if (error.response) {
      const errorMessage = error.response?.data?.message || 'Error de servidor';
      throw new Error(errorMessage);
    } else {
      throw new Error('Error de conexión');
    }
  }
}
import { User } from "../../types/User";

export enum UserActionTypes {
  API_RESPONSE_SUCCESS = '@@users/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@users/API_RESPONSE_ERROR',
  SAVE_USER = '@@users/SAVE_USER',
  UPDATE_USER = '@@users/UPDATE_USER',
  SAVE_ADMIN_USER = '@@users/SAVE_ADMIN_USER',
  UPDATE_ADMIN_USER = '@@users/UPDATE_ADMIN_USER',
  GET_USERS = '@@users/GET_USERS',
  GET_ADMIN_USER = '@@users/GET_ADMIN_USER',
  DELETE_USER = '@@users/DELETE_USER',
  OPEN_MODAL = '@@users/OPEN_MODAL',
  CLOSE_MODAL = '@@users/CLOSE_MODAL',
}

export interface UserActionType {
  type:
    | UserActionTypes.API_RESPONSE_SUCCESS
    | UserActionTypes.API_RESPONSE_ERROR
    | UserActionTypes.SAVE_USER
    | UserActionTypes.UPDATE_USER
    | UserActionTypes.UPDATE_ADMIN_USER
    | UserActionTypes.SAVE_ADMIN_USER
    | UserActionTypes.GET_USERS
    | UserActionTypes.GET_ADMIN_USER
    | UserActionTypes.DELETE_USER
    | UserActionTypes.OPEN_MODAL
    | UserActionTypes.CLOSE_MODAL
    payload?:
      { 
        actionType?: string;
        data?: User | User[] | {};
        error?: string;
      };
}

// common success
export const userApiResponseSuccess = (
  actionType: string,
  data: User | {} 
): UserActionType => ({
  type: UserActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const userApiResponseError = (
  actionType: string,
  error: string
): UserActionType => ({
  type: UserActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});



export interface AddUserPayload {
  data: {
    email: string;
    password: string;
    name: string;
    surname: string;
    lastname: string;
    optionalEmail?: string | null | undefined; // Allow null
    localPhone?: string | null | undefined; // Allow null
    localExtension?: string | null | undefined; // Allow null
    directPhone?: string | null | undefined; // Allow null
    directExtension?: string | null | undefined; // Allow null
    cellPhone?: string | null | undefined; // Allow null
    optionalCellPhone?: string | null | undefined; // Allow null
    avatarLink?: string | null | undefined; // Allow null
    role: number;
    hospital: number;
    area: number;
    adminArea?: string | null | undefined;
    adminPosition?: string | null | undefined;
    professionalLicense?: string | null | undefined; // Allow null
    degree?: string | null | undefined; // Allow null
  }
}


export interface UpdateUserPayload {
  data: {
    id: number;
    email: string;
    password: string;
    name: string;
    surname: string;
    lastname: string;
    optionalEmail?: string | null | undefined; // Allow null
    localPhone?: string | null | undefined; // Allow null
    localExtension?: string | null | undefined; // Allow null
    directPhone?: string | null | undefined; // Allow null
    directExtension?: string | null | undefined; // Allow null
    cellPhone?: string | null | undefined; // Allow null
    optionalCellPhone?: string | null | undefined; // Allow null
    avatarLink?: string | null | undefined; // Allow null
    role: number;
    hospital: number;
    area: number;
    adminArea?: string | null | undefined;
    adminPosition?: string | null | undefined;
    professionalLicense?: string | null | undefined; // Allow null
    degree?: string | null | undefined; // Allow null
  }
}

export interface UserState {
  loading: boolean;
  user: {};
  users: User[];
  modal: boolean;
  error: string | undefined;
  deleteStatus: string;
}
import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { deleteFileImageApi, deleteFileLabApi, getFileImgApi, getFileLabApi, getFilesByStudyApi, getStudiesApi } from "../../helpers";
import { StudiesActionTypes, studyApiResponseError, studyApiResponseSuccess } from "./studyTypes";


/**
 * Get  Studies
 * @param {*} payload - 
 */
function* getStudies(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {
    console.log(action.payload.data.id)
    const studies = yield call(getStudiesApi,action.payload.data.id);
    yield put(studyApiResponseSuccess(StudiesActionTypes.LIST_STUDIES, studies));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(studyApiResponseError(StudiesActionTypes.LIST_STUDIES, errorMessage));
  }
}

/**
 * Get Files Studies
 * @param {*} payload - 
 */
function* getFilesStudies(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {
    console.log(action.payload.data.id)
    const files = yield call(getFilesByStudyApi,action.payload.data.id);
    yield put(studyApiResponseSuccess(StudiesActionTypes.LIST_FILE_STUDIES, files));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(studyApiResponseError(StudiesActionTypes.LIST_FILE_STUDIES, errorMessage));
  }
}


/**
 * Get File
 * @param {*} payload - 
 */
function* getFileImage(action: { type: string; payload: { data : { name:string } } }): SagaIterator {
  try {
    const response = yield call(getFileImgApi, action.payload.data.name); 
    var fileLink = document.createElement('a');
    fileLink.href = response.url;
    fileLink.setAttribute('download', action.payload.data.name);
    document.body.appendChild(fileLink);
    fileLink.click();
    yield put(studyApiResponseSuccess(StudiesActionTypes.GET_IMAGE_FILE_STUDY, ""));
  } catch (error: any) {
    yield put(studyApiResponseError(StudiesActionTypes.GET_IMAGE_FILE_STUDY, error));
  }
}


/**
 * Get File
 * @param {*} payload - 
 */
function* getFileLab(action: { type: string; payload: { data : { name:string } } }): SagaIterator {
  try {
    const response = yield call(getFileLabApi, action.payload.data.name); 
    var fileLink = document.createElement('a');
    fileLink.href = response.url;
    fileLink.setAttribute('download', action.payload.data.name);
    document.body.appendChild(fileLink);
    fileLink.click();
    yield put(studyApiResponseSuccess(StudiesActionTypes.GET_LAB_FILE_STUDY, ""));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(studyApiResponseError(StudiesActionTypes.GET_LAB_FILE_STUDY, errorMessage));
  }
}



/**
 * Delete File Image Studies
 * @param {*} payload - 
 */
function* deleteFileImage(action: { type: string; payload: { data : { id:number , name: string} } }): SagaIterator {
  try {
    const files = yield call(deleteFileImageApi,action.payload.data.id,action.payload.data.name);
    yield put(studyApiResponseSuccess(StudiesActionTypes.DELETE_FILE_STUDY_IMAGE, files));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(studyApiResponseError(StudiesActionTypes.DELETE_FILE_STUDY_IMAGE, errorMessage));
  }
}

/**
 * Delete File Image Studies
 * @param {*} payload - 
 */
function* deleteFileLab(action: { type: string; payload: { data : { id:number , name: string} } }): SagaIterator {
  try {
    const files = yield call(deleteFileLabApi,action.payload.data.id,action.payload.data.name);
    yield put(studyApiResponseSuccess(StudiesActionTypes.DELETE_FILE_STUDY_LAB, files));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(studyApiResponseError(StudiesActionTypes.DELETE_FILE_STUDY_LAB, errorMessage));
  }
}

function* openModalStudy(): SagaIterator{
  yield put(studyApiResponseSuccess(StudiesActionTypes.OPEN_MODAL,""));
}

function* closeModalStudy(): SagaIterator{
  yield put(studyApiResponseSuccess(StudiesActionTypes.CLOSE_MODAL,""));

}


export function* watchOpenModalStudy() {
  yield takeEvery(StudiesActionTypes.OPEN_MODAL, openModalStudy);
}
export function* watchCloseModalStudy() {
  yield takeEvery(StudiesActionTypes.CLOSE_MODAL, closeModalStudy);
}



export function* watchGetStudies() {
  yield takeEvery(StudiesActionTypes.LIST_STUDIES, getStudies);
}

export function* watchGetFilesStudies() {
  yield takeEvery(StudiesActionTypes.LIST_FILE_STUDIES, getFilesStudies);
}

export function* watchGetFileImage() {
  yield takeEvery(StudiesActionTypes.GET_IMAGE_FILE_STUDY, getFileImage);
}


export function* watchGetFileLab() {
  yield takeEvery(StudiesActionTypes.GET_LAB_FILE_STUDY, getFileLab);
}

export function* watchDeleteFileImage() {
  yield takeEvery(StudiesActionTypes.DELETE_FILE_STUDY_IMAGE, deleteFileImage);
}

export function* watchDeleteFileLab() {
  yield takeEvery(StudiesActionTypes.DELETE_FILE_STUDY_LAB, deleteFileLab);
}

function* studySaga() {
  yield all([
    fork(watchGetStudies),
    fork(watchGetFilesStudies),
    fork(watchGetFileImage),
    fork(watchGetFileLab),
    fork(watchDeleteFileImage),
    fork(watchDeleteFileLab),
    fork(watchOpenModalStudy),
    fork(watchCloseModalStudy)
  ]);
}

export default studySaga;

import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { login as loginApi} from "../../helpers/api/auth";
// helpers
import { APICore, setAuthorization } from "../../helpers/api/apiCore";



// actions
import { authApiResponseSuccess, authApiResponseError } from "./actions";

// constants
import { AuthActionTypes } from "./constants";

interface LoginAction {
  payload: {
    data:{
      email: string;
      password: string;
    }  
  };
  type: string;
}

const api = new APICore();

/**
 * Login the user
 * @param {LoginAction} action - The login action with email and password
 */
function* login(action: LoginAction): SagaIterator {
  const { email, password } = action.payload.data;
  try {
    const response = yield call(loginApi, { email, password });
    const data = response.data;
    const user = data.user;
    // adding token to user
    user.token = data.token;
    api.setLoggedInUser(user);
    setAuthorization(user.token);

    // Dispatch success action with user data
    yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
  } catch (error: any) {
    // Dispatch error action
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
  try {
   // yield call(logoutApi); // Ensure logout API is called, if necessary
    api.setLoggedInUser(null);
    setAuthorization(null);

    // Dispatch success action for logout
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    // Dispatch error action for logout
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

// Watchers
function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

// Root saga
function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
  ]);
}

export default authSaga;

import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { AddEquipmentPayload, UpdateEquipmentPayload } from "../../types/Equipment";
import { deleteEquipmentApi, getEquipmentsByIdOrderApi, saveEquipmentApi, updateEquipmentApi } from "../../helpers";
import { equipmentApiResponseError, equipmentApiResponseSuccess, EquipmentsActionTypes } from "./equipmentTypes";



/**
 * Create Directory
 * @param {*} payload - 
 */
function* createEquipment(action: { type: string; payload: AddEquipmentPayload }): SagaIterator {
  try {
    const equipment = yield call(saveEquipmentApi, action.payload);
    yield put(equipmentApiResponseSuccess(EquipmentsActionTypes.CREATE_EQUIPMENT, equipment));

  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(equipmentApiResponseError(EquipmentsActionTypes.CREATE_EQUIPMENT, errorMessage));
  }
}


/**
 * Update Equipment
 * @param {*} payload - 
 */

function* updateEquipment(action: { type: string; payload: UpdateEquipmentPayload }): SagaIterator {
  try {
    const equipment = yield call(updateEquipmentApi, action.payload);
    yield put(equipmentApiResponseSuccess(EquipmentsActionTypes.UPDATE_EQUIPMENT, equipment));

  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(equipmentApiResponseError(EquipmentsActionTypes.UPDATE_EQUIPMENT, errorMessage));
  }
}

/**
 * Get Equipments
 * @param {*} payload - 
 */
function* getEquipments(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {   
    yield call(getEquipmentsByIdOrderApi, action.payload.data.id);
    yield put(equipmentApiResponseSuccess(EquipmentsActionTypes.GET_EQUIPMENT_BY_ID, action.payload.data.id));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(equipmentApiResponseError(EquipmentsActionTypes.GET_EQUIPMENT_BY_ID, errorMessage));
  }
}


function* deleteEquipment(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {   
    yield call(deleteEquipmentApi, action.payload.data.id);
    yield put(equipmentApiResponseSuccess(EquipmentsActionTypes.DELETE_EQUIPMENT, action.payload.data.id));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(equipmentApiResponseError(EquipmentsActionTypes.DELETE_EQUIPMENT, errorMessage));
  }
}


function* openModalEquipment({
  type,
}:any): SagaIterator{
    yield put(equipmentApiResponseSuccess(EquipmentsActionTypes.OPEN_EQUIPMENT_MODAL,""));
 
}

export function* watchOpenModalEquipment() {
  yield takeEvery(EquipmentsActionTypes.OPEN_EQUIPMENT_MODAL,openModalEquipment);
}

function* closeModalEquipment({
  type,
}:any): SagaIterator{
    yield put(equipmentApiResponseSuccess(EquipmentsActionTypes.CLOSE_EQUIPMENT_MODAL,""));
 
}

export function* watchDeleteEquipment() {
  yield takeEvery(EquipmentsActionTypes.DELETE_EQUIPMENT, deleteEquipment);
}


export function* watchGetEquipments() {
  yield takeEvery(EquipmentsActionTypes.GET_EQUIPMENT_BY_ID, getEquipments);
}


export function* watchUpdateEquipment() {
  yield takeEvery(EquipmentsActionTypes.UPDATE_EQUIPMENT, updateEquipment);
}


export function* watchCreateEquipment() {
  yield takeEvery(EquipmentsActionTypes.CREATE_EQUIPMENT, createEquipment);
}


export function* watchCloseModalEquipment() {
  yield takeEvery(EquipmentsActionTypes.CLOSE_EQUIPMENT_MODAL,closeModalEquipment);
}


function* equipmentSaga() {
  yield all([
    fork(watchGetEquipments),
    fork(watchCloseModalEquipment),
    fork(watchOpenModalEquipment),
    fork(watchCreateEquipment),
    fork(watchUpdateEquipment),
    fork(watchDeleteEquipment),
  ]);
}

export default equipmentSaga;

import { Navigate } from 'react-router-dom';
import { APICore } from '../helpers/api/apiCore';

const Root = () => {
    const api = new APICore();
    type RoleMapType = {
        [key: number]: string; // Define that the keys are numbers and values are strings
    };
    
    // Mapeo de roles a URL
    const roleToUrlMap : RoleMapType= {
        1: 'admin/hospitales',
        2: 'admin/areas',
        3: 'admin/tablero',
        4: 'landing',
        5: 'admin/casos',
        6: 'admin/laboratorio',
        7: 'admin/requerimientos/imagen',
        9: 'admin/nota-medica',
        10: 'admin/casos',
    };

    // Obtener la URL raíz según la autenticación del usuario
    const getRootUrl = () => {
        if (api.isUserAuthenticated()) {
            const loggedInUser = api.getLoggedInUser();
            return roleToUrlMap[loggedInUser.role.id] || 'landing'; // Retorna la URL según el rol o 'landing' por defecto
        }
        return 'landing'; // Si no está autenticado, redirigir a 'landing'
    };

    const url = getRootUrl();

    return <Navigate to={`/${url}`} replace />;
};

export default Root;
import { UserActionType, UserActionTypes } from "./userTypes";


export const getUsers = (): UserActionType => ({
  type: UserActionTypes.GET_USERS,
});


export const saveUser = (
  email: string,
  password: string,
  name: string,
  surname: string,
  lastname: string,
  optionalEmail: string | null | undefined,
  localPhone: string | null | undefined,
  localExtension: string | null | undefined,
  directPhone: string | null | undefined,
  directExtension: string,
  cellPhone: string,
  optionalCellPhone: string,
  avatarLink: string,
  role: number,
  hospital: number,
  area: number,
  adminArea: string,
  adminPosition: string,
  professionalLicense: string | null | undefined,
  degree: string | null | undefined,
): UserActionType => ({
  type: UserActionTypes.SAVE_USER,
  payload: {
    data: {
      email,
      password,
      name,
      surname,
      lastname,
      optionalEmail,
      localPhone,
      localExtension,
      directPhone,
      directExtension,
      cellPhone,
      optionalCellPhone,
      avatarLink,
      role,
      hospital,
      area,
      adminPosition,
      adminArea,
      professionalLicense,
      degree
    }
  },
});




export const updateUser = (
  id: number,
  email: string,
  password: string,
  name: string,
  surname: string,
  lastname: string,
  optionalEmail: string | null | undefined,
  localPhone: string | null | undefined,
  localExtension: string | null | undefined,
  directPhone: string | null | undefined,
  directExtension: string,
  cellPhone: string | null | undefined,
  optionalCellPhone: string,
  avatarLink: string | null | undefined,
  role: number,
  hospital: number,
  area: number,
  adminPosition: string,
  adminArea: string,
  professionalLicense: string | null | undefined,
  degree: string | null | undefined
): UserActionType => ({
  type: UserActionTypes.UPDATE_USER,
  payload: {
    data: {
      id,
      email,
      password,
      name,
      surname,
      lastname,
      optionalEmail,
      localPhone,
      localExtension,
      directPhone,
      directExtension,
      cellPhone,
      optionalCellPhone,
      avatarLink,
      role,
      hospital,
      area,
      adminPosition,
      adminArea,
      professionalLicense,
      degree

    }

  },
});

export const deleteUser = (id: number): UserActionType => ({
  type: UserActionTypes.DELETE_USER,
  payload: {
    data:{
      id
    }
  }
});

export const openModalUser = (): UserActionType =>{
  return { type: UserActionTypes.OPEN_MODAL}
}


export const closeModalUser = (): UserActionType =>{
  return { type: UserActionTypes.CLOSE_MODAL}
}
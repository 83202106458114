import { EquipmentsActionsType, EquipmentsActionTypes, EquipmentState } from "./equipmentTypes";



const INIT_STATE : EquipmentState = {
    path: "",
    loading: false,
    equipments: [],
    modal: false,
    error: ""
};



const equipmentReducer = (state: EquipmentState = INIT_STATE, action: EquipmentsActionsType): any => {
   
    switch (action.type) {
        case EquipmentsActionTypes.API_RESPONSE_SUCCESS:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case EquipmentsActionTypes.CREATE_EQUIPMENT: {
                    return {
                        ...state, 
                        equipment : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case EquipmentsActionTypes.UPDATE_EQUIPMENT: {
                    return {
                        ...state, 
                        equipment : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case EquipmentsActionTypes.DELETE_EQUIPMENT: {
                    return {
                        ...state, 
                        equipment : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case EquipmentsActionTypes.GET_FILE: {
                    return {
                        ...state, 
                        equipment : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case EquipmentsActionTypes.GET_EQUIPMENT_BY_ID: {
                    return {
                        ...state, 
                        equipments : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case EquipmentsActionTypes.OPEN_EQUIPMENT_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:true,
                        error:''
                    };
                }
                case EquipmentsActionTypes.CLOSE_EQUIPMENT_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                        error:''
                    };
                }
                default:
                    return { ...state };
            }

        case EquipmentsActionTypes.API_RESPONSE_ERROR:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case EquipmentsActionTypes.CREATE_EQUIPMENT: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }
                case EquipmentsActionTypes.UPDATE_EQUIPMENT: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }
                case EquipmentsActionTypes.GET_FILE: {
                    return {
                        ...state, 
                        equipment : action.payload.data,
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }
                case EquipmentsActionTypes.GET_EQUIPMENT_BY_ID: {
                    return {
                        ...state, 
                        equipments : action.payload.data,
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }

                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default equipmentReducer;

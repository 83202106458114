import React from 'react';
import { BrowserRouter, Routes , Route } from 'react-router-dom';
// All layouts containers
import DefaultLayout from '../layouts/Default'
import VerticalLayout from '../layouts/Vertical';

// routes
import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from './index';
import { selectLayoutData } from '../redux/layout/selectorLayout';
import { useSelector } from 'react-redux';

interface RoutesProps {}

const MainRoutes = (props: RoutesProps) => {
    const { layoutType,layoutWidth, menuPosition,leftSideBarTheme,leftSideBarType,
        showSidebarUserInfo,topbarTheme,isOpenRightSideBar } = useSelector(selectLayoutData);
        
    const layout = {
        layoutType: layoutType,
        layoutWidth: layoutWidth,
        leftSideBarTheme: leftSideBarTheme,
        leftSideBarType: leftSideBarType,
        showRightSidebar: true,
        menuPosition: menuPosition,
        showSidebarUserInfo: showSidebarUserInfo,
        topbarTheme:topbarTheme,
        isOpenRightSideBar:isOpenRightSideBar
    }

    return (
        <BrowserRouter >
            <Routes>
                {/* Public Routes */}
                <Route element={<DefaultLayout {...props} layout={layout} />}>
                    {publicProtectedFlattenRoutes.map((route: any, index: number) => (
                        !route.children && (
                            <Route
                                key={index}
                                path={route.path}
                                element={route.element}
                            />
                        )
                    ))}
                </Route>

             {/* Auth Protected Routes */}
           {   <Route element={<VerticalLayout {...props} />}>
                    {authProtectedFlattenRoutes.map((route: any, index: number) => (
                        !route.children && (
                            <Route
                                key={index}
                                path={route.path}
                                element={route.element}
                             />
                        )
                    ))}
                </Route> }
            </Routes>
        </BrowserRouter>
    );
};

export default MainRoutes;
import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  saveEnterpriseApi,
  getEnterprisesApi,
  getFileEnterpriseApi,
  updateEnterpriseApi,
  deleteEnterpriseApi
} from "../../helpers";
import { AddEnterprisePayload, enterpriseApiResponseError, enterpriseApiResponseSuccess, EnterprisesActionTypes, UpdateEnterprisePayload } from "./enterpriseTypes";
import { Enterprise } from "../../types/Enterprise";
import { ResponseFile } from "../../types/ResponseFile";


/**
 * Get Enterprises
 * @param {*} payload - 
 */
function* getEnterprises(): SagaIterator {
  try {
    const enterprises: Enterprise[] = yield call(getEnterprisesApi);
    yield put(enterpriseApiResponseSuccess(EnterprisesActionTypes.GET_ALL_ENTERPRISE, enterprises));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(enterpriseApiResponseError(EnterprisesActionTypes.GET_ALL_ENTERPRISE, errorMessage));
  }
}

/**
 * Create Enterprise
 * @param {*} payload - 
 */
function* createEnterprise(action: { type: string; payload: AddEnterprisePayload }): SagaIterator {
  try {
    const directory = yield call(saveEnterpriseApi, action.payload);
    yield put(enterpriseApiResponseSuccess(EnterprisesActionTypes.CREATE_ENTERPRISE, directory));
    yield call(getEnterprises);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(enterpriseApiResponseError(EnterprisesActionTypes.CREATE_ENTERPRISE, errorMessage));
  }
}

/**
 * Update Enterprise
 * @param {*} payload - 
 */

function* updateEnterprise(action: { type: string; payload: UpdateEnterprisePayload }): SagaIterator {
  try {
    const directory = yield call(updateEnterpriseApi, action.payload);
    yield put(enterpriseApiResponseSuccess(EnterprisesActionTypes.UPDATE_ENTERPRISE, directory));
    yield call(getEnterprises);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(enterpriseApiResponseError(EnterprisesActionTypes.UPDATE_ENTERPRISE, errorMessage));
  }
}


/**
 * Get File
 * @param {*} payload - 
 */
function* getFileEnterprise(action: { type: string; payload: {name: string}}): SagaIterator {
  try {
    const response: ResponseFile = yield call(getFileEnterpriseApi,action.payload.name);
    yield put(enterpriseApiResponseSuccess(EnterprisesActionTypes.GET_FILE, response.url));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(enterpriseApiResponseError(EnterprisesActionTypes.GET_FILE, errorMessage));
  }
}


/**
 * Delete
 * @param {*} payload - 
 */
function* deleteEnterprise(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {   
    yield call(deleteEnterpriseApi, action.payload.data.id);
    yield put(enterpriseApiResponseSuccess(EnterprisesActionTypes.DELETE_ENTERPRISE, action.payload.data.id));
    yield call(getEnterprises);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(enterpriseApiResponseError(EnterprisesActionTypes.DELETE_ENTERPRISE, errorMessage));
  }
}


function* openModalEnterprise({
  type,
}:any): SagaIterator{
    yield put(enterpriseApiResponseSuccess(EnterprisesActionTypes.OPEN_ENTERPRISE_MODAL,""));
 
}

export function* watchOpenModalEnterprise() {
  yield takeEvery(EnterprisesActionTypes.OPEN_ENTERPRISE_MODAL,openModalEnterprise);
}

function* closeModalEnterprise({
  type,
}:any): SagaIterator{
    yield put(enterpriseApiResponseSuccess(EnterprisesActionTypes.CLOSE_ENTERPRISE_MODAL,""));
 
}

export function* watchCloseModalEnterprise() {
  yield takeEvery(EnterprisesActionTypes.CLOSE_ENTERPRISE_MODAL,closeModalEnterprise);
}


export function* watchGetFileEnterprises() {
  yield takeEvery(EnterprisesActionTypes.GET_FILE, getFileEnterprise);
}

export function* watchCreateEnterprise() {
  yield takeEvery(EnterprisesActionTypes.CREATE_ENTERPRISE, createEnterprise);
}

export function* watchDeleteEnterprise() {
  yield takeEvery(EnterprisesActionTypes.DELETE_ENTERPRISE, deleteEnterprise);
}


export function* watchUpdateEnterprise() {
  yield takeEvery(EnterprisesActionTypes.UPDATE_ENTERPRISE, updateEnterprise);
}

export function* watchGetEnterprises() {
  yield takeEvery(EnterprisesActionTypes.GET_ALL_ENTERPRISE, getEnterprises);
}


function* enterpriseSaga() {
  yield all([
    fork(watchGetEnterprises),
    fork(watchCloseModalEnterprise),
    fork(watchOpenModalEnterprise),
    fork(watchCreateEnterprise),
    fork(watchGetFileEnterprises),
    fork(watchUpdateEnterprise),
    fork(watchDeleteEnterprise)
  ]);
}

export default enterpriseSaga;



import { AddProviderPayload, UpdateProviderPayload } from "../../redux/providers/providerTypes";
import { Provider } from "../../types/Provider";
import { APICore } from "./apiCore";

const api = new APICore();
const baseUrl = "/providers";

export const saveProviderApi = async (provider: AddProviderPayload):Promise<Provider> => {
  try {
    const response = await api.create(`${baseUrl}`, provider.data);;
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
} 

export const updateProviderApi = async (user: UpdateProviderPayload):Promise<Provider> => {
  try {
    const response = await api.update(`${baseUrl}`, user.data);;
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const getProvidersApi = async ():Promise<Provider[]> => {
  try {
    const response = await api.get(`${baseUrl}/all`, '');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const getProviderByUserApi = async (idUser : number):Promise<Provider> => {
  try {
    const response = await api.get(`${baseUrl}/user/${idUser}`,'');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const getProviderByIdEnterpriseApi = async (id : number):Promise<Provider> => {
  try {
    const response = await api.get(`${baseUrl}/enterprise/${id}`,'');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}


export const deleteProviderApi = async ( id: number): Promise<Boolean> => {
  try {
    const response = await api.delete(`${baseUrl}/${id}`);
    if (response.status === 200) {
      return true; 
    } else {
      throw new Error('Error al eliminar el proveedor');
    }
  } catch (error: any) {
    if (error.response) {
      const errorMessage = error.response?.data?.message || 'Error de servidor';
      throw new Error(errorMessage);
    } else {
      throw new Error('Error de conexión');
    }
  }
}
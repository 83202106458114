import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { getAllScalesApi, getFileScaleApi } from "../../helpers";
import { PatientScalesActionTypes, scaleApiResponseError, scaleApiResponseSuccess } from "./scalesTypes";
import { PatientScale } from "../../types/PatientScale";


/**
 * Get Files Scales
 * @param {*} payload - 
 */
function* getPatientScales(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {
    const scales: PatientScale = yield call(getAllScalesApi, action.payload.data.id);
    yield put(scaleApiResponseSuccess(PatientScalesActionTypes.GET_PATIENT_SCALES, scales));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(scaleApiResponseError(PatientScalesActionTypes.GET_PATIENT_SCALES, errorMessage));
  }
}

export function* watchGetPatientScales() {
  yield takeEvery(PatientScalesActionTypes.GET_PATIENT_SCALES, getPatientScales);
}



/**
 * Get File
 * @param {*} payload - 
 */
function* getFileScale(action: { type: string; payload: { data : { name:string } } }): SagaIterator {
  try {
    const response = yield call(getFileScaleApi, action.payload.data.name);
   
    var fileLink = document.createElement('a');
    fileLink.href = response.url;
    fileLink.setAttribute('download', action.payload.data.name);
    document.body.appendChild(fileLink);
    fileLink.click();
    yield put(scaleApiResponseSuccess(PatientScalesActionTypes.GET_FILE_SCALE, ""));
  } catch (error: any) {
    yield put(scaleApiResponseError(PatientScalesActionTypes.GET_FILE_SCALE, error));
  }
}

export function* watchGetFileScale() {
  yield takeEvery(PatientScalesActionTypes.GET_FILE_SCALE, getFileScale);
}


function* scaleFilesSaga() {
  yield all([
    fork(watchGetPatientScales),
    fork(watchGetFileScale),
  ]);
}

export default scaleFilesSaga;

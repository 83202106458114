import { EquipmentsActionsType, EquipmentsActionTypes } from "./equipmentTypes";

export const saveEquipmentWithFile = (
  orderNumber: number,
  brand: string,
  model: string,
  quantity: number,
  characteristic: string | null | undefined,
  price: number,
  total: number,
): EquipmentsActionsType => ({
  type: EquipmentsActionTypes.CREATE_EQUIPMENT,
  payload: {
    data: {
      orderNumber,
      brand,
      model,
      quantity,
      characteristic,
      price,
      total,
    }
  },
});

export const updateEquipment = (
  id: number,
  orderNumber: number,
  brand: string,
  model: string,
  quantity: number,
  characteristic: string | null | undefined,
  price: number,
  total: number,
): EquipmentsActionsType => ({
  type: EquipmentsActionTypes.UPDATE_EQUIPMENT,
  payload: {
    data: {
      id,
      orderNumber,
      brand,
      model,
      quantity,
      characteristic,
      price,
      total,
    }
  },
});

export const deleteEquipment = (id: number): EquipmentsActionsType => {
  return { type: EquipmentsActionTypes.DELETE_EQUIPMENT, payload: { data: { id } } }
}

export const getEquipments = (id: number): EquipmentsActionsType => {
  return { type: EquipmentsActionTypes.GET_EQUIPMENT_BY_ID, payload: { data: { id } } }
}

export const getFileEquipments = (name: string): EquipmentsActionsType => {
  return { type: EquipmentsActionTypes.GET_FILE, payload: { data: { name } } }
}

export const openModalEquipment = () => {
  return { type: EquipmentsActionTypes.OPEN_EQUIPMENT_MODAL }
}

export const closeModalEquipment = () => {
  return { type: EquipmentsActionTypes.CLOSE_EQUIPMENT_MODAL }
}
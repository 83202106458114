import { TreatingDoctor } from "../../types/TreatingDoctor";

export enum DoctorsActionTypes {
  API_RESPONSE_SUCCESS = '@@doctors/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@doctors/API_RESPONSE_ERROR',
  UPDATE_DIGITAL_SIGN  = '@@doctors/UPDATE_DIGITAL_SIGN',
  UPDATE_DIGITAL_STAMP  = '@@doctors/UPDATE_DIGITAL_STAMP',
  GET_TREATING_DOCTOR_BY_USER = '@@doctors/GET_TREATING_DOCTOR_BY_USER'
}

export interface DoctorsActionsType {
  type:
  | DoctorsActionTypes.API_RESPONSE_SUCCESS
  | DoctorsActionTypes.API_RESPONSE_ERROR
  | DoctorsActionTypes.UPDATE_DIGITAL_SIGN
  | DoctorsActionTypes.UPDATE_DIGITAL_STAMP
  | DoctorsActionTypes.GET_TREATING_DOCTOR_BY_USER
  payload?: {
    actionType?: string;
    data?: TreatingDoctor | TreatingDoctor[] | {};
    error?: string;
  };
}


// common success
export const doctorsApiResponseSuccess = (
  actionType: string,
  data: TreatingDoctor | {}
): DoctorsActionsType => ({
  type: DoctorsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const doctorsApiResponseError = (
  actionType: string,
  error: string
): DoctorsActionsType => ({
  type: DoctorsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export interface DoctorState {
  loading: boolean;
  doctors: TreatingDoctor[];
  doctor: TreatingDoctor;
  error: string | null;
}
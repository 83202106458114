import { StudyFile } from "../../types/ClinicalRequirementFile";
import { Study } from "../../types/Study";

export enum StudiesActionTypes {
  API_RESPONSE_SUCCESS = '@@studies/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@studies/API_RESPONSE_ERROR',
  LIST_STUDIES = '@@studies/LIST_STUDIES',
  LIST_FILE_STUDIES = '@@studies/LIST_FILE_STUDIES',
  CLOSE_FILE_UPLOAD = '@@studies/CLOSE_FILE_UPLOAD',
  OPEN_MODAL = '@@studies/OPEN_MODAL',
  CLOSE_MODAL = '@@studies/CLOSE_MODAL',
  DELETE_FILE_STUDY_LAB = '@@studies/DELETE_FILE_STUDY_LAB',
  DELETE_FILE_STUDY_IMAGE = '@@studies/DELETE_FILE_STUDY_IMAGE',
  GET_IMAGE_FILE_STUDY = '@@studies/GET_IMAGE_FILE_STUDY',
  GET_LAB_FILE_STUDY = '@@studies/GET_LAB_FILE_STUDY',
  UPDATE_STATUS_STUDY = '@@studies/UPDATE_STATUS_STUDY',
}


export interface StudyActionTypes {
  type:
    | StudiesActionTypes.API_RESPONSE_SUCCESS
    | StudiesActionTypes.API_RESPONSE_ERROR
    | StudiesActionTypes.LIST_STUDIES
    | StudiesActionTypes.LIST_FILE_STUDIES
    | StudiesActionTypes.CLOSE_FILE_UPLOAD
    | StudiesActionTypes.OPEN_MODAL
    | StudiesActionTypes.CLOSE_MODAL
    | StudiesActionTypes.DELETE_FILE_STUDY_LAB
    | StudiesActionTypes.DELETE_FILE_STUDY_IMAGE
    | StudiesActionTypes.GET_IMAGE_FILE_STUDY
    | StudiesActionTypes.GET_LAB_FILE_STUDY
    | StudiesActionTypes.UPDATE_STATUS_STUDY
    payload?: {
      actionType?: string;
      data?: Study | Study[] |{};
      error?: string;
  };
}

export interface StudyState {
  loading: boolean;
  studies: Study[];
  studyFiles: StudyFile[];
  modal: boolean;
  error: string;
}


// common success
export const studyApiResponseSuccess = (
  actionType: string,
  data: Study | {}
): StudyActionTypes => ({
  type: StudiesActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});


// common error
export const studyApiResponseError = (
  actionType: string,
  error: string
): StudyActionTypes => ({
  type: StudiesActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});



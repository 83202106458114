
import { HospitalDirectoryActionsType, HospitalDirectoryActionType } from "./directoryTypes";

export const getDirectories = (): HospitalDirectoryActionsType => ({
  type: HospitalDirectoryActionType.GET_DIRECTORIES,
});


export const saveDirectory = (
  name: string,
  email:string,
  surname: string,
  lastname: string,
  position: string,
  optionalEmail: string| null | undefined,
  localPhone: string| null | undefined,
  localExtension: number| null | undefined,
  directPhone: string| null | undefined,
  cellPhone: string| null | undefined,
  avatarLink:string,
  hospital:number,
): HospitalDirectoryActionsType => ({
  type: HospitalDirectoryActionType.SAVE_DIRECTORY,
  payload: {
    data :{
      name,
      email,
      surname,
      lastname,
      position,
      optionalEmail,
      localPhone,
      localExtension,
      directPhone ,
      cellPhone,
      avatarLink,
      hospital
    }

  },
});


export const updateDirectory = (
  id:number| null | undefined,
  name: string,
  email:string,
  surname: string,
  lastname: string,
  position: string,
  optionalEmail: string| null | undefined,
  localPhone: string| null | undefined,
  localExtension: number| null | undefined,
  directPhone: string| null | undefined,
  cellPhone: string| null | undefined,
  avatarLink:string,
  hospital:number,
): HospitalDirectoryActionsType => ({
  type: HospitalDirectoryActionType.UPDATE_DIRECTORY,
  payload: {
    data:{
      id,
      name,
      email,
      surname,
      lastname,
      position,
      optionalEmail,
      localPhone,
      localExtension,
      directPhone ,
      cellPhone,
      avatarLink,
      hospital
    }
  },
});

export const deleteDirectory = (id: number): HospitalDirectoryActionsType => ({
  type: HospitalDirectoryActionType.DELETE_DIRECTORY,
  payload: {
    data:{
      id
    }
  }
});

export const openModalDirectory = (): HospitalDirectoryActionsType =>{
  return { type: HospitalDirectoryActionType.OPEN_MODAL}
}
export const closeModalDirectory = ():HospitalDirectoryActionsType =>{
  return { type: HospitalDirectoryActionType.CLOSE_MODAL}
}
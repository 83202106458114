import { HospitalDirectoryActionsType, HospitalDirectoryActionType, HospitalDirectoryState } from "./directoryTypes";

enum STATUS {
    SUCCESS = "SUCCESS",
    FAIL = "FAIL"
  }

const INIT_STATE: HospitalDirectoryState = {
    loading: false,
    hospitalDirectories: [],
    modal: false,
    error:'',
    deleteStatus: ''
};

const directoryReducer = (state: HospitalDirectoryState = INIT_STATE, action: HospitalDirectoryActionsType): any => {

    switch (action.type) {
        case HospitalDirectoryActionType.API_RESPONSE_SUCCESS:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case HospitalDirectoryActionType.SAVE_DIRECTORY: {
                    return {
                        ...state,
                        hospitalDirectories: [],
                        loading: false,
                        modal: false,
                        error:'',
                        deleteStatus: ''
                    };
                }
                case HospitalDirectoryActionType.UPDATE_DIRECTORY: {
                    return {
                        ...state,
                        hospitalDirectories: [],
                        modal: false,
                        loading: false,
                        error:'',
                        deleteStatus: ''
                    };
                }
                case HospitalDirectoryActionType.GET_DIRECTORIES: {
                    return {
                        ...state,
                        directories: action.payload.data,
                        loading: false,
                        modal: false,
                        error:'',
                        deleteStatus: ''
                    };
                }
                case HospitalDirectoryActionType.DELETE_DIRECTORY: {
                    return {
                        ...state,
                        directories: [],
                        loading: false,
                        modal: false,
                        error:'',
                        deleteStatus: STATUS.SUCCESS
                    };
                }
                case HospitalDirectoryActionType.OPEN_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal: true,
                        error:'',
                        deleteStatus: ''
                    };
                }
                case HospitalDirectoryActionType.CLOSE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal: false,
                        error:'',
                        deleteStatus: ''
                    };
                }
                default:
                    return { ...state };
            }

        case HospitalDirectoryActionType.API_RESPONSE_ERROR:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case HospitalDirectoryActionType.SAVE_DIRECTORY: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                         deleteStatus:''
                    };
                }
                case HospitalDirectoryActionType.UPDATE_DIRECTORY: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                         deleteStatus:''
                    };
                }


                case HospitalDirectoryActionType.GET_DIRECTORIES: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        deleteStatus:''
                    };
                }

                case HospitalDirectoryActionType.DELETE_DIRECTORY: {
                    return {
                        ...state,
                        loading: false,
                        error:action.payload.error,
                        deleteStatus: STATUS.FAIL
                    };
                }
              
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default directoryReducer;

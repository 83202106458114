import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

// actions
import { changeSidebarType } from '../redux/actions';

// constants
import { LayoutTypes, SideBarTypes } from '../constants';
import { changeBodyAttribute } from '../utils/layout';
import { useSelector } from 'react-redux';
import { selectLayoutData } from '../redux/layout/selectorLayout';
import { useRedux } from '../hooks';
import { Outlet } from 'react-router-dom';

// code splitting and lazy loading
const Topbar = React.lazy(() => import('./Topbar'));
const LeftSidebar = React.lazy(() => import('./LeftSidebar'));
const Footer = React.lazy(() => import('./Footer'));
const RightSidebar = React.lazy(() => import('./RightSidebar/'));

const loading = () => <div className=""></div>;

interface VerticalLayoutProps {
  children?: React.ReactNode;
}

const VerticalLayout = ({ children = null }: VerticalLayoutProps) => {
  const {
    layoutWidth,
    menuPosition,
    leftSideBarTheme,
    leftSideBarType,
    showSidebarUserInfo,
    topbarTheme,
  } = useSelector(selectLayoutData);

  const { dispatch } = useRedux();
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

  /*
   * Layout defaults
   */
  useEffect(() => {
    changeBodyAttribute('data-layout-mode', LayoutTypes.LAYOUT_VERTICAL);
  }, []);

  useEffect(() => {
    changeBodyAttribute('data-layout-width', layoutWidth);
  }, [layoutWidth]);

  useEffect(() => {
    changeBodyAttribute('data-layout-menu-position', menuPosition);
  }, [menuPosition]);

  useEffect(() => {
    changeBodyAttribute('data-sidebar-color', leftSideBarTheme);
  }, [leftSideBarTheme]);

  useEffect(() => {
    changeBodyAttribute('data-sidebar-size', leftSideBarType);
  }, [leftSideBarType]);

  useEffect(() => {
    changeBodyAttribute('data-topbar-color', topbarTheme);
  }, [topbarTheme]);

  useEffect(() => {
    changeBodyAttribute('data-sidebar-showuser', 'false');
  }, [showSidebarUserInfo]);

  /**
   * Open the menu when having a mobile screen
   */
  const openMenu = () => {
    setIsMenuOpened((prevState) => !prevState);

    if (document.body) {
      if (isMenuOpened) {
        document.body.classList.remove('sidebar-enable');
      } else {
        document.body.classList.add('sidebar-enable');
      }
    }
  };

  const updateDimensions = useCallback(() => {
    // activate the condensed sidebar if smaller devices like iPad or tablet
    if (window.innerWidth > 768 && window.innerWidth <= 1028) {
      dispatch(changeSidebarType(SideBarTypes.LEFT_SIDEBAR_TYPE_CONDENSED));
    } else if (window.innerWidth > 1028) {
      dispatch(changeSidebarType(SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT));
    }
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [updateDimensions]);

  const isCondensed: boolean =
    leftSideBarType === SideBarTypes.LEFT_SIDEBAR_TYPE_CONDENSED;
    const AreasScreen = lazy(() => import("../pages/areas"));
  return (
    <>
      <div id="wrapper">
        <Suspense fallback={loading()}>
          <Topbar openLeftMenuCallBack={openMenu} hideLogo={false} />
        </Suspense>
        <Suspense fallback={loading()}>
          <LeftSidebar isCondensed={isCondensed} />
        </Suspense>
        <div className="content-page">
          <div className="content">
            <Container fluid>
              <Suspense fallback={loading()}>{
                  <Outlet />
                }</Suspense>
            </Container>
          </div>

          <Suspense fallback={loading()}>
            <Footer />
          </Suspense>
        </div>
      </div>

      <Suspense fallback={loading()}>
        <RightSidebar />
      </Suspense>
    </>
  );
};

export default VerticalLayout;
import { PatientScale } from "../../types/PatientScale";

export enum PatientScalesActionTypes {
  API_RESPONSE_SUCCESS = '@@scales/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@scales/API_RESPONSE_ERROR',
  GET_PATIENT_SCALES = '@@scales/GET_PATIENT_SCALES',
  GET_FILE_SCALE = '@@scales/GET_FILE_SCALE'
}

export interface PatientScalesActionsType {
  type:
    | PatientScalesActionTypes.API_RESPONSE_SUCCESS
    | PatientScalesActionTypes.API_RESPONSE_ERROR
    | PatientScalesActionTypes.GET_PATIENT_SCALES
    | PatientScalesActionTypes.GET_FILE_SCALE
    payload?: {
      actionType?: string;
      data?: PatientScale | PatientScale[] | {};
      error?: string;
    };
}

// common success
export const scaleApiResponseSuccess = (
  actionType: string,
  data: PatientScale[] | {}
): PatientScalesActionsType => ({
  type: PatientScalesActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const scaleApiResponseError = (
  actionType: string,
  error: string
): PatientScalesActionsType => ({
  type: PatientScalesActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});


export interface PatientScaleState {
  loading: boolean;
  patientScale: PatientScale[];
  error: string | null;
}



interface PatientScaleData {
  name: string;
  file:  File | null;
}

export interface AddPatientScalePayload {
  data: {
    name: string;
    file:  File | null;
  }
}

export interface UpdatePatientScalePayload {
  data: {
    id: number;
    name: string;
    file:  File | null;
  }
}


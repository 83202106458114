import { RequirementsActionType, RequirementsActionTypes, RequirementState } from './requirementTypes';


const INIT_STATE: RequirementState = {
    loading: false,
    requirements:[],
    error: '',
};


const requirementReducer = (state: RequirementState = INIT_STATE, action: RequirementsActionTypes): any => {
   
    switch (action.type) {
        case RequirementsActionType.API_RESPONSE_SUCCESS:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case RequirementsActionType.LIST_IMAGE_REQUIREMENTS: {
                    return {
                        ...state,
                        requirements: action.payload.data,
                        error:''
                    };
                }

                case RequirementsActionType.LIST_LAB_REQUIREMENTS: {
                    return {
                        ...state,
                        requirements: action.payload.data,
                        error:''
                    };
                }

                default:
                    return { ...state };
            }

        case RequirementsActionType.API_RESPONSE_ERROR:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case RequirementsActionType.LIST_IMAGE_REQUIREMENTS: {
                    return {
                        ...state,
                        error:action.payload.error
                    };
                }

                case RequirementsActionType.LIST_LAB_REQUIREMENTS: {
                    return {
                        ...state,
                        error:action.payload.error
                    };
                }

               
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default requirementReducer;

import { Attendance } from "../../types/Attendance";

export enum ProviderAttendanceActionTypes {
  API_RESPONSE_SUCCESS = '@@providers-attendance/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@providers-attendance/API_RESPONSE_ERROR',
  UPDATE_ATTENDANCE = '@@providers-attendance/UPDATE_ATTENDANCE',
  GET_NOT_ATTENDANCE = '@@providers-attendance/GET_NOT_ATTENDANCE',
  GET_ATTENDANCE_BY_PROVIDER = '@@providers-attendance/GET_ATTENDANCE_BY_PROVIDER',
  RESET_STATE = '@@providers-attendance/RESET_STATE',
}

export interface ProviderAttendanceActionType {
  type:
      | ProviderAttendanceActionTypes.API_RESPONSE_SUCCESS
      | ProviderAttendanceActionTypes.API_RESPONSE_ERROR
      | ProviderAttendanceActionTypes.UPDATE_ATTENDANCE
      | ProviderAttendanceActionTypes.GET_NOT_ATTENDANCE
      | ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PROVIDER
      | ProviderAttendanceActionTypes.RESET_STATE
  payload: {
      actionType?: string;
      data?: Attendance | {};
      error?: string;
  };
}



interface AttendanceData {
  id:number;
  contactDate: string;
  arrivalDate : string;
  attended : boolean;
  evaluated: boolean;
  patient : PatientData;
}

export interface Patient{
  id: number;
  name: string;
  sex: string;
  age: number;
  folio: string;
}

export interface AttendanceUpdate {
  patient:  number;
  provider: number;
  attended: boolean;
}
export interface FatherAttendance {
  providers: AttendanceUpdate[];
}

// common success
export const attendanceApiResponseSuccess = (
  actionType: string,
  data: AttendanceData | {}
): ProviderAttendanceActionType => ({
  type: ProviderAttendanceActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const attendanceApiResponseError = (
  actionType: string,
  error: string
): ProviderAttendanceActionType => ({
  type: ProviderAttendanceActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export interface AttendanceState {
  loading: boolean;
  attendances:Attendance[];
  error: string;
}

interface AttendanceData {
  id:number;
  contactDate: string;
  arrivalDate : string;
  attended : boolean;
  evaluated: boolean;
  patient : PatientData;
}
interface PatientData {
  id: number;
  name: string;
  sex: string;
  age: number;
  folio: string;
}

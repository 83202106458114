import { PatientScalesActionTypes } from "./scalesTypes"


export const getAllScales = (id : number)=>{
  return { type: PatientScalesActionTypes.GET_PATIENT_SCALES ,payload: {
    data:{
      id
    }
  }}
} 

export const getFileScale = (name : string)=>{
  return { type: PatientScalesActionTypes.GET_FILE_SCALE ,payload: {
    data:{
      name
    }
  }}
} 


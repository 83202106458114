import { MedicalNote } from "../../types/MedicalNote";
import { Patient } from "../../types/Patient";


export enum MedicalNotesActionTypes {
    API_RESPONSE_SUCCESS = '@@medicals_notes/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@medicals_notes/API_RESPONSE_ERROR',
    CREATE_NOTE = '@@medicals_notes/CREATE_NOTE',
    SAVE_NOTE = '@@medicals_notes/SAVE_NOTE',
    DOWNLOAD_NOTE = '@@medicals_notes/DOWNLOAD_NOTE',
    UPDATE_NOTE = '@@medicals_notes/UPDATE_NOTE',
    GET_NOTES = '@@medicals_notes/GET_NOTES',
    GET_PATIENTS = '@@medicals_notes/GET_PATIENTS',
    DELETE_NOTE = '@@medicals_notes/DELETE_NOTE',
    OPEN_NOTE_MODAL = '@@medicals_notes/OPEN_NOTE_MODAL',
    CLOSE_NOTE_MODAL = '@@medicals_notes/CLOSE_NOTE_MODAL',
}

export interface MedicalNotesActionType {
    type:
      | MedicalNotesActionTypes.API_RESPONSE_SUCCESS
      | MedicalNotesActionTypes.API_RESPONSE_ERROR
      | MedicalNotesActionTypes.CREATE_NOTE
      | MedicalNotesActionTypes.SAVE_NOTE
      | MedicalNotesActionTypes.DOWNLOAD_NOTE
      | MedicalNotesActionTypes.UPDATE_NOTE
      | MedicalNotesActionTypes.GET_NOTES
      | MedicalNotesActionTypes.DELETE_NOTE
      | MedicalNotesActionTypes.OPEN_NOTE_MODAL
      | MedicalNotesActionTypes.CLOSE_NOTE_MODAL
      payload?: {
        actionType?: string;
        data?: MedicalNote | {};
        error?: string;
    };
  }


  interface MedicalNoteData {
    treatingDoctor: number;
    description: string;
    patient: number;
    treatment: number;
  }
  // common success
  export const medicalNoteApiResponseSuccess = (
    actionType: string,
    data: MedicalNoteData | {}
  ): MedicalNotesActionType => ({
    type: MedicalNotesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
  });
  // common error
  export const medicalNoteApiResponseError = (
    actionType: string,
    error: string
  ): MedicalNotesActionType => ({
    type: MedicalNotesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
  });



  interface MedicalNoteData {
    treatingDoctor: number;
    description: string;
    patient: number;
    treatment: number;
}

interface PatientData {
    id:number;
    name: string;
    sex: string;
    age: number;
    folio: string;
}


export interface MedicalNoteState {
    loading: boolean;
    medicalNote:MedicalNote;
    medicalNotes:MedicalNote[];
    patients:Patient[];
    modal: boolean;
}
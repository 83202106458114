
import { SaveFolioPayload, UpdateFolioPayload } from "../../types/Folio";
import { OrderNumber } from "../../types/OrderNumber";
import { ResponseFile } from "../../types/ResponseFile";
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/orders-number";



export const getFoliosByIdVisitApi = async (id: number): Promise<OrderNumber[]> => {
  try {
    const response = await api.get(`${baseUrl}/attendance/${id}`,'');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}


export const getFileFolioApi = async (name: string): Promise<ResponseFile> => {
  try {
    const response = await api.get(`${baseUrl}/files/${name}`, '');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}


export const saveFolioApi = async (folio: SaveFolioPayload): Promise<ResponseFile> => {
  try {
    const response = await api.create(`${baseUrl}`,folio);
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const updateFolioApi = async (folio: UpdateFolioPayload): Promise<ResponseFile> => {
  try {
    const response = await api.update(`${baseUrl}`,folio);
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const approveFolioApi = async (id: number): Promise<Boolean> => {
  try {
    const response = await api.update(`${baseUrl}/${id}/3`,'');
    if (response.status === 200) {
      return true;
    } else {
      throw new Error('Error al actualizar status');
    }
  } catch (error: any) {
    if (error.response) {
      const errorMessage = error.response?.data?.message || 'Error de servidor';
      throw new Error(errorMessage);
    } else {
      throw new Error('Error de conexión');
    }
  }

}

export const rejectFolioApi = async (id: number): Promise<Boolean> => {
  try {
    const response = await api.update(`${baseUrl}/${id}/4`,'');
    if (response.status === 200) {
      return true;
    } else {
      throw new Error('Error al actualizar status');
    }
  } catch (error: any) {
    if (error.response) {
      const errorMessage = error.response?.data?.message || 'Error de servidor';
      throw new Error(errorMessage);
    } else {
      throw new Error('Error de conexión');
    }
  }

}

export const closeUploadApi = async (id: number): Promise<Boolean> => {
  try {
    const response = await api.update(`${baseUrl}/${id}/2`,'');
    if (response.status === 200) {
      return true;
    } else {
      throw new Error('Error al actualizar status');
    }
  } catch (error: any) {
    if (error.response) {
      const errorMessage = error.response?.data?.message || 'Error de servidor';
      throw new Error(errorMessage);
    } else {
      throw new Error('Error de conexión');
    }
  }

}
import {jwtDecode} from "jwt-decode";
import axios from "axios";

import config from "../../config";


// Crear instancia de Axios sin autorización
const axiosNoAuth = axios.create({
  baseURL: config.API_URL,
});

// Crear instancia de Axios con autorización
const axiosAuth = axios.create({
  baseURL: config.API_URL,
});

// content type
axiosAuth.defaults.headers.post["Content-Type"] = "application/json";
axiosAuth.defaults.baseURL = config.API_URL;

// intercepting to capture errors
axiosAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    let message = 'Ocurrió un error inesperado en el servidor.';
    
    // Verificar si el error es un 500
    if (error.response && error.response.status === 500) {
      message = 'Error interno del servidor. Por favor, intenta de nuevo más tarde.';
    } else if (error.response) {
      // Otros códigos de error
      switch (error.response.status) {
        case 401:
          message = 'Credenciales inválidas';
          break;
        case 403:
          message = 'Acceso prohibido';
          break;
        case 404:
          message = 'Datos no encontrados';
          break;
        default:
          message = error.response.data?.message || 'Ocurrió un error inesperado';
      }
    } else if (error.request) {
      message = 'No se pudo recibir respuesta del servidor. Por favor, verifica tu conexión a internet.';
    }
    // Rechazar la promesa con el error para que pueda ser manejado
    return Promise.reject(error);
  }
);

const AUTH_SESSION_KEY = "cerebrum_user";

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  if (token) axiosAuth.defaults.headers.common["Authorization"] = "Bearer " + token;
  else delete axiosAuth.defaults.headers.common["Authorization"];
};

const getUserFromCookie = () => {
  const user = sessionStorage.getItem(AUTH_SESSION_KEY);
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};
class APICore {

  getWithType =<T> (url: string, params: any) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axiosAuth.get<T>(`${url}?${queryString}`, params);
    } else {
      response = axiosAuth.get<T>(`${url}`, params);
    }
    return response;
  };

    /**
   * Updates data
   */
    updateWithType =<T> (url: string, data: any) => {
      return axiosAuth.put<T>(url, data);
    };

    updateWithFileType =<T> (url: string, data: any) => {
      const formData = new FormData();
      for (const k in data) {
        formData.append(k, data[k]);
      }
  
      const config: any = {
        headers: {
          ...axiosAuth.defaults.headers,
          "content-type": "multipart/form-data",
        },
      };
      return axiosAuth.put<T>(url, formData, config);
    };


  /**
   * Fetches data from given url
   */
  get = (url: string, params: any) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axiosAuth.get(`${url}?${queryString}`, params);
    } else {
      response = axiosAuth.get(`${url}`, params);
    }
    return response;
  };

  getFile = (url: string, params: any) => {
    let response ;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axiosAuth.get<Blob>(`${url}?${queryString}`, { responseType: "blob" });
    } else {
      response = axiosAuth.get<Blob>(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = (urls: string, params: any) => {
    const reqs = [];
    let queryString = "";
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
    }

    for (const url of urls) {
      reqs.push(axiosAuth.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
   * post given data to url
   */
  create = (url: string, data: any) => {
    return axiosAuth.post(url, data);
  };

  /**
   * Updates patch data
   */
  updatePatch = (url: string, data: any) => {
    return axiosAuth.patch(url, data);
  };

  /**
   * Updates data
   */
  update = (url: string, data: any) => {
    return axiosAuth.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = (url: string) => {
    return axiosAuth.delete(url);
  };

  /**
   * post given data to url with file
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config: any = {
      headers: {
        ...axiosAuth.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axiosAuth.post(url, formData, config);
  };

  /**
   * post given data to url with file
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config: any = {
      headers: {
        ...axiosAuth.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axiosAuth.put(url, formData, config);
  };

  isUserAuthenticated = () => {
    const user = this.getLoggedInUser();

    if (!user) {
      return false;
    }
    const decoded: any = jwtDecode(user.token);

    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  };

  setLoggedInUser = (session: any) => {
    if (session)
      sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
    else {
      sessionStorage.removeItem(AUTH_SESSION_KEY);
    }
  };
  /**
   * Returns the logged in user
   */
  getLoggedInUser = () => {
    return getUserFromCookie();
  };

  setUserInSession = (modifiedUser: any) => {
    let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
    if (userInfo) {
      const { token, user } = JSON.parse(userInfo);
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };
}

/*
Check if token available in session
*/
let user = getUserFromCookie();
if (user) {
  const { token } = user;
  if (token) {
    setAuthorization(token);
  }
}

export { APICore,axiosNoAuth, setAuthorization };

import { ProviderActionType, ProviderActionTypes, ProvideState } from "./providerTypes";



const INIT_STATE : ProvideState = {
    loading: false,
    providers: [],
    provider: {},
    modal: false,
    error: undefined,
    deleteStatus: ""
};




const providerReducer = (state: ProvideState = INIT_STATE, action: ProviderActionType): any => {
   
    switch (action.type) {
        case ProviderActionTypes.API_RESPONSE_SUCCESS:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case ProviderActionTypes.SAVE_PROVIDER: {
                    return {
                        ...state,
                        provider: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case ProviderActionTypes.UPDATE_PROVIDER: {
                    return {
                        ...state,
                        provider: action.payload.data,
                        modal: false,
                        loading: false,
                        error:''
                    };
                }
                case ProviderActionTypes.GET_PROVIDERS: {
                    return {
                        ...state,
                        providers: action.payload.data,
                        loading: false,
                    };
                }
                case ProviderActionTypes.GET_PROVIDER_BY_USER: {
                    return {
                        ...state,
                        provider: action.payload.data,
                        loading: false,
                    };
                }
                case ProviderActionTypes.GET_PROVIDER_BY_ENTERPRISE: {
                    return {
                        ...state,
                        providers: action.payload.data,
                        loading: false,
                    };
                }
                case ProviderActionTypes.OPEN_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:true,
                        error:''
                    };
                }
                case ProviderActionTypes.CLOSE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                    };
                }

                default:
                    return { ...state };
            }

        case ProviderActionTypes.API_RESPONSE_ERROR:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case ProviderActionTypes.SAVE_PROVIDER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case ProviderActionTypes.UPDATE_PROVIDER: {
                    return {
                        ...state,
                        hospital: action.payload.data,
                        loading: false,
                    };
                }
                case ProviderActionTypes.GET_PROVIDERS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case ProviderActionTypes.GET_PROVIDER_BY_ENTERPRISE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case ProviderActionTypes.GET_PROVIDER_BY_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
              
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default providerReducer;

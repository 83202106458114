import { AttendanceState, ProviderAttendanceActionType, ProviderAttendanceActionTypes } from "./attendanceTypes";


const INIT_STATE: AttendanceState = {
    loading: false,
    attendances: [],
    error: '',
};



const attendanceReducer = (state: AttendanceState = INIT_STATE, action: ProviderAttendanceActionType): any => {

    switch (action.type) {
        case ProviderAttendanceActionTypes.API_RESPONSE_SUCCESS:
            if (!action.payload) return { ...state };
            switch (action.payload.actionType) {
                case ProviderAttendanceActionTypes.UPDATE_ATTENDANCE: {
                    return {
                        ...state,
                        loading: false,
                        error: ''
                    };
                }

                case ProviderAttendanceActionTypes.GET_NOT_ATTENDANCE: {
                    return {
                        ...state,
                        attendances: action.payload.data,
                        loading: false,
                    };
                }

                case ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PROVIDER: {
                    return {
                        ...state,
                        attendances: action.payload.data,
                        loading: false,
                    };
                }

                case ProviderAttendanceActionTypes.RESET_STATE: {
                    return {
                        ...state,
                        attendances: [],
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case ProviderAttendanceActionTypes.API_RESPONSE_ERROR:
            if (!action.payload) return { ...state };
            switch (action.payload.actionType) {

                case ProviderAttendanceActionTypes.UPDATE_ATTENDANCE:
                case ProviderAttendanceActionTypes.GET_NOT_ATTENDANCE:
                case ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PROVIDER:
                    {
                        return {
                            ...state,
                            loading: false,
                            modal: false,
                            error: action.payload.data
                        };
                    }

                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default attendanceReducer;

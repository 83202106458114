import { HospitalActionsType, HospitalActionTypes } from "./hospitalTypes";


export const getHospitals = (): HospitalActionsType => ({
  type: HospitalActionTypes.GET_HOSPITALS,
});


export const saveHospital = (
  name: string,
  rfc: string,
  businessName: string,
  addressStreet: string,
  addressSuburb: string,
  addressMayoralty : string,
  addressPostalCode : number,
  addressState : string,
  branch: string| null| undefined,
  file:  File | null | undefined,
): HospitalActionsType => ({
  type: HospitalActionTypes.SAVE_HOSPITAL,
  payload: {
    data:{
      name,
      rfc,
      businessName,
      addressStreet,
      addressSuburb,
      addressMayoralty,
      addressPostalCode,
      addressState ,
      branch,
      file : file,
    } 
  }
});

export const updateHospital = (
  id: number,
  name: string,
  rfc: string,
  businessName: string,
  addressStreet: string,
  addressSuburb: string,
  addressMayoralty : string,
  addressPostalCode : number,
  addressState : string,
  branch: string| null| undefined,
  file:  File | null | undefined,
): HospitalActionsType => ({
  type: HospitalActionTypes.UPDATE_HOSPITAL,
  payload: {
    data:{
      id,
      name,
      rfc,
      businessName,
      addressStreet,
      addressSuburb,
      addressMayoralty,
      addressPostalCode,
      addressState ,
      branch,
      file ,
    }
  },
});

export const deleteHospital = (id: number): HospitalActionsType => ({
  type: HospitalActionTypes.DELETE_HOSPITAL,
  payload: {
    data:{
      id
    }
  }
});

export const openModalHospital = (): HospitalActionsType =>{
  return { type: HospitalActionTypes.OPEN_MODAL}
}

export const closeModalHospital = (): HospitalActionsType =>{
  return { type: HospitalActionTypes.CLOSE_MODAL}
}
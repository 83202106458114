import { Requirement } from "../../pages/studies/RequirementImaging";
import { ClinicalRequirement } from "../../types/ClinicalRequirement";


export enum RequirementsActionType {
  API_RESPONSE_SUCCESS = '@@requirements/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@requirements/API_RESPONSE_ERROR',
  LIST_IMAGE_REQUIREMENTS= '@@requirements/LIST_IMAGE_REQUIREMENTS',
  LIST_LAB_REQUIREMENTS= '@@requirements/LIST_LAB_REQUIREMENTS'
}


export interface RequirementsActionTypes {
  type:
    | RequirementsActionType.API_RESPONSE_SUCCESS
    | RequirementsActionType.API_RESPONSE_ERROR
    | RequirementsActionType.LIST_IMAGE_REQUIREMENTS
    | RequirementsActionType.LIST_LAB_REQUIREMENTS
    payload?: {
      actionType?: string;
      data?: ClinicalRequirement | ClinicalRequirement[] |{};
      error?: string;
  };
}


// common success
export const requirementApiResponseSuccess = (
  actionType: string,
  data: Requirement | {}
): RequirementsActionTypes => ({
  type: RequirementsActionType.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const requirementApiResponseError = (
  actionType: string,
  error: string
): RequirementsActionTypes => ({
  type: RequirementsActionType.API_RESPONSE_ERROR,
  payload: { actionType, error },
});



export interface RequirementState {
  loading: boolean;
  requirements: ClinicalRequirement[];
  error: string | null;
}


import { APICore } from '../../helpers/api/apiCore';
import { AuthActionTypes } from './constants';

const api = new APICore();

// Inicializando el estado
const INIT_STATE: State = {
  user: api.getLoggedInUser(), // Suponiendo que esto puede ser UserData o null
  loading: false,
  userLoggedIn: false,
  userSignUp: false,
  userLogout: false,
  passwordReset: false,
  resetPasswordSuccess: null,
  error: null,
};

// Definición de tipos
interface UserData {
  id?: number| undefined;
  email: string;
  name?: string| undefined;
  surname?: string| undefined;
  lastName?: string| undefined;
  optionalEmail?: string| undefined;
  localPhone?: string| undefined;
  localExtension?: number| undefined;
  directPhone?: string| undefined;
  cellPhone?: string| undefined;
  avatarLink?: string| undefined;
  creationDate?: string| undefined;
  updateDate?: string| undefined;
  deleteDate?: string| undefined;
  password?: string| undefined;
}

interface AuthActionType {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET;
  payload?: {
    actionType?: string;
    data?: UserData | {} | undefined;
    error?: string;
  };
}

interface State {
  user: UserData | null | {}; // Cambié a UserData | null
  loading: boolean;
  userLoggedIn: boolean;
  userSignUp: boolean;
  userLogout: boolean;
  passwordReset: boolean;
  resetPasswordSuccess: boolean | null; // Cambié a boolean | null
  error: string | null; // Cambié a string | null
}

const Auth = (state: State = INIT_STATE, action: AuthActionType): State => {
  switch (action.type) {
    case AuthActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload?.actionType) {
        case AuthActionTypes.LOGIN_USER:
          return {
            ...state,
            user: action.payload.data ?? null, // Usar `??` para manejar undefined
            userLoggedIn: true,
            loading: false,
            error: null,
          };

        case AuthActionTypes.LOGOUT_USER:
          return {
            ...state,
            user: null, // Ahora esto es válido
            loading: false,
            userLogout: true,
            error: null,
          };

        default:
          return { ...state };
      }

    case AuthActionTypes.API_RESPONSE_ERROR:
      switch (action.payload?.actionType) {
        case AuthActionTypes.LOGIN_USER:
          return {
            ...state,
            error: 'El usuario y contraseña son incorrectos',
            userLoggedIn: false,
            loading: false,
          };

        default:
          return { ...state };
      }

    case AuthActionTypes.LOGIN_USER:
      return { ...state, loading: true, userLoggedIn: false };

    case AuthActionTypes.LOGOUT_USER:
      return { ...state, loading: true, userLogout: false };

    case AuthActionTypes.RESET:
      return {
        ...state,
        loading: false,
        error: null,
        userSignUp: false,
        userLoggedIn: false,
        userLogout: false,
        passwordReset: false,
        resetPasswordSuccess: null,
      };

    default:
      return { ...state };
  }
};

export default Auth;


// constants
import { HospitalActionTypes, HospitalActionsType, HospitalState } from './hospitalTypes';

  enum STATUS {
    SUCCESS = "SUCCESS",
    FAIL = "FAIL"
  }


  const INIT_STATE: HospitalState = {
    loading: false,
    hospitals: [],
    modal: false,
    error:'',
    deleteStatus: ''
  };


const hospitalReducer = (state: HospitalState = INIT_STATE, action: HospitalActionsType): any => {
   
    switch (action.type) {
        case HospitalActionTypes.API_RESPONSE_SUCCESS:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case HospitalActionTypes.SAVE_HOSPITAL: {
                    return {
                        ...state,
                        hospitals: [],
                        loading: false,
                        modal: false,
                        error:'',
                        deleteStatus:''
                    };
                }
                case HospitalActionTypes.UPDATE_HOSPITAL: {
                    return {
                        ...state,
                        hospital: [],
                        modal: false,
                        loading: false,
                        error:'',
                        deleteStatus:''
                    };
                }
                case HospitalActionTypes.GET_HOSPITALS: {
                    return {
                        ...state,
                        hospitals: action.payload.data,
                        loading: false,
                        deleteStatus:''
                    };
                }
                case HospitalActionTypes.DELETE_HOSPITAL: {
                    return {
                        ...state,
                        hospitals: [],
                        loading: false,
                        deleteStatus: STATUS.SUCCESS
                    };
                }

                case HospitalActionTypes.OPEN_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:true,
                        error:'',
                        deleteStatus:''
                    };
                }
                case HospitalActionTypes.CLOSE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                        error:'',
                        deleteStatus:''
                    };
                }

                default:
                    return { 
                       
                        ...state 
                    };
            }

        case HospitalActionTypes.API_RESPONSE_ERROR:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case HospitalActionTypes.SAVE_HOSPITAL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        deleteStatus:''
                    };
                }
                case HospitalActionTypes.UPDATE_HOSPITAL: {
                    return {
                        ...state,
                        hospital: action.payload.data,
                        loading: false,
                        deleteStatus:''
                    };
                }
                case HospitalActionTypes.GET_HOSPITALS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        deleteStatus:''
                    };
                }
                case HospitalActionTypes.DELETE_HOSPITAL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        deleteStatus:STATUS.FAIL
                    };
                }
              
                default:
                    return { ...state };
            }
    
        default:
            return { ...state };
    }
};

export default hospitalReducer;

import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import areaSaga from './areas/saga';
import hospitalSaga from './hospitals/saga';
import directorySaga from './directories/saga';
import userSaga from './users/saga'
import enterpriseSaga from './enterprises/saga';
import patientsSaga from './patients/saga';
import attendanceSaga from './attendance/saga';
import scaleFilesSaga from './scales/saga';
import providerSaga from './providers/saga';
import equipmentSaga from './equipments/saga';
import folioSaga from './folios/saga';
import studySaga from './studies/saga';
import requirementSaga from './requirements/saga';
import treatingDoctorSaga from './doctors/saga';
import medicalNoteSaga from './notes/saga';

/*


import protocolSaga from './protocols/saga';

import scheduleSaga from './schedules/saga'

import visitsSaga from './visits/saga';


import imagingSaga from './imaging/saga';
import laboratorySaga from './laboratory/saga';


*/

export default function* rootSaga() {
    yield all([authSaga(),
          layoutSaga(),
          areaSaga(),
          hospitalSaga(),
          directorySaga(),
          userSaga(),
          enterpriseSaga(),
          patientsSaga(),
          attendanceSaga(),
          scaleFilesSaga(),
          providerSaga(),
          equipmentSaga(),
          folioSaga(),
          studySaga(),
          requirementSaga(),
          studySaga(),
          treatingDoctorSaga(),
          medicalNoteSaga()
 /*        
          protocolSaga(),
          userSaga(),
          scheduleSaga(),
      
          visitsSaga(),
          
         
       
          imagingSaga(),
          laboratorySaga(),
          medicalNoteSaga(),
          attendanceSaga(),
          scaleFilesSaga(), */
            ]);
}

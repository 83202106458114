
import { AddEnterprisePayload, UpdateEnterprisePayload } from "../../redux/enterprises/enterpriseTypes";
import { Enterprise } from "../../types/Enterprise";
import { ResponseFile } from "../../types/ResponseFile";
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/enterprises";


export const saveEnterpriseApi = async (enterprise: AddEnterprisePayload):Promise<Enterprise> => {
  try {
    const response = await api.createWithFile(`${baseUrl}`, enterprise.data);;
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const updateEnterpriseApi = async (hospital: UpdateEnterprisePayload) => {
  try {
    const response = await api.updateWithFile(`${baseUrl}`, hospital.data);;
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const getEnterprisesApi = async ():Promise<Enterprise[]> => {
  try {
    const response = await api.get(`${baseUrl}/all`, '');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const deleteEnterpriseApi = async ( id: number): Promise<Boolean> => {
  try {
    const response = await api.delete(`${baseUrl}/${id}`);
    if (response.status === 200) {
      return true; 
    } else {
      throw new Error('Error al eliminar el hospital');
    }
  } catch (error: any) {
    if (error.response) {
      const errorMessage = error.response?.data?.message || 'Error de servidor';
      throw new Error(errorMessage);
    } else {
      throw new Error('Error de conexión');
    }
  }
}

export const getFileEnterpriseApi = async (name: string):Promise<ResponseFile> => {
  try {
    const response = await api.get(`${baseUrl}/files/${name}`, '');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}


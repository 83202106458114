import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";


// helpers
import {
  saveProviderApi,
  deleteProviderApi,
  updateProviderApi,
  getProvidersApi,
  getProviderByUserApi,
  getProviderByIdEnterpriseApi
  
} from "../../helpers";
import { AddProviderPayload, ProviderActionTypes, providerApiResponseError, providerApiResponseSuccess, UpdateProviderPayload } from "./providerTypes";


/**
 * Get Providers
 * @param {*} payload - 
 */
function* getProviders(): SagaIterator {
  try {
    const providers = yield call(getProvidersApi, );

    yield put(providerApiResponseSuccess(ProviderActionTypes.GET_PROVIDERS, providers));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(providerApiResponseError(ProviderActionTypes.GET_PROVIDERS, errorMessage));
  }
}


/**
 * Create Provider
 * @param {*} payload - 
 */
function* createProvider(action: { type: string; payload: AddProviderPayload }): SagaIterator {
  try {
    const provider = yield call(saveProviderApi, action.payload);
    yield put(providerApiResponseSuccess(ProviderActionTypes.SAVE_PROVIDER, provider));
    yield call(getProviders);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(providerApiResponseError(ProviderActionTypes.SAVE_PROVIDER, errorMessage));
  }
}


/**
 * Create Provider
 * @param {*} payload - 
 */
function* updateProvider(action: { type: string; payload: UpdateProviderPayload }): SagaIterator {
  try {
    const provider = yield call(updateProviderApi, action.payload);
    yield put(providerApiResponseSuccess(ProviderActionTypes.UPDATE_PROVIDER, provider));
    yield call(getProviders);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(providerApiResponseError(ProviderActionTypes.UPDATE_PROVIDER, errorMessage));
  }
}

function* deleteProvider(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {   
    yield call(deleteProviderApi, action.payload.data.id);
    yield put(providerApiResponseSuccess(ProviderActionTypes.DELETE_PROVIDER, action.payload.data.id));
    yield call(getProviders);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(providerApiResponseError(ProviderActionTypes.DELETE_PROVIDER, errorMessage));
  }
}


/**
 * Get Providers By User
 * @param {*} payload - 
 */
function* getProviderByUserId(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {
    const provider = yield call(getProviderByUserApi, action.payload.data.id );
    yield put(providerApiResponseSuccess(ProviderActionTypes.GET_PROVIDER_BY_USER, provider));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(providerApiResponseError(ProviderActionTypes.GET_PROVIDER_BY_USER, errorMessage));
  }
}

/**
 * Get Providers By User
 * @param {*} payload - 
 */
function* getProviderByEnterpriseId(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {
    const response = yield call(getProviderByIdEnterpriseApi,action.payload.data.id );
    const data = response.data;
    yield put(providerApiResponseSuccess(ProviderActionTypes.GET_PROVIDER_BY_ENTERPRISE, data));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(providerApiResponseError(ProviderActionTypes.GET_PROVIDER_BY_ENTERPRISE, errorMessage));
  }
}


function* closeModalProvider(): SagaIterator{
  yield put(providerApiResponseSuccess(ProviderActionTypes.CLOSE_MODAL,""));

}

function* openModalProvider(): SagaIterator{
    yield put(providerApiResponseSuccess(ProviderActionTypes.OPEN_MODAL,""));
 
}

export function* watchOpenModalProvider() {
  yield takeEvery(ProviderActionTypes.OPEN_MODAL,openModalProvider);
}


export function* watchGetProviderByEnterpriseId() {
  yield takeEvery(ProviderActionTypes.GET_PROVIDER_BY_ENTERPRISE, getProviderByEnterpriseId);
}


export function* watchDeleteProvider() {
  yield takeEvery(ProviderActionTypes.DELETE_PROVIDER,deleteProvider);
}

export function* watchCloseModalProvider() {
  yield takeEvery(ProviderActionTypes.CLOSE_MODAL,closeModalProvider);
}

export function* watchCreateProvider() {
  yield takeEvery(ProviderActionTypes.SAVE_PROVIDER, createProvider);
}
export function* watchUpdateProvider() {
  yield takeEvery(ProviderActionTypes.UPDATE_PROVIDER, updateProvider);
}

export function* watchGetProviders() {
  yield takeEvery(ProviderActionTypes.GET_PROVIDERS, getProviders);
}

export function* watchGetProviderByUserId() {
  yield takeEvery(ProviderActionTypes.GET_PROVIDER_BY_USER, getProviderByUserId);
}


function* providerSaga() {
  yield all([
    fork(watchOpenModalProvider),
    fork(watchCloseModalProvider),
    fork(watchCreateProvider),
    fork(watchUpdateProvider),
    fork(watchGetProviders),
    fork(watchGetProviderByUserId),
    fork(watchGetProviderByEnterpriseId),
    fork(watchDeleteProvider),
  ]);
}

export default providerSaga;

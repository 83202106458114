
import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import areaReducer from './areas/reducers'
import hospitalReducer from './hospitals/reducers'
import directoryReducer from './directories/reducers';
import userReducer from './users/reducers';
import enterpriseReducer from './enterprises/reducers';
import patientReducer from './patients/reducers';
import attendanceReducer  from './attendance/reducers';
import patientScaleReducer from './scales/reducers';
import providerReducer from './providers/reducers';
import equipmentReducer from './equipments/reducers';
import folioReducer from './folios/reducers';
import requirementReducer from './requirements/reducers';
import studyReducer from './studies/reducers';
import treatingDoctorReducer from './doctors/reducers';
import noteReducer from './notes/reducers';
/*
import Protocol from './protocols/reducers';

import Schedule  from './schedules/reducers';

import RequirementImaging from './imaging/reducers';
import RequirementLaboratory from './laboratory/reducers';
import StudyImaging from './studies/reducers';
import MedicalNote from './notes/reducers';

import Files from './files/reducers';
*/
export default combineReducers({
    Auth,
    Layout,
    areaReducer,
    hospitalReducer,
    directoryReducer,
    userReducer,
    enterpriseReducer,
    patientReducer,
    attendanceReducer,
    patientScaleReducer,
    providerReducer,
    equipmentReducer,
    folioReducer,
    requirementReducer,
    studyReducer,
    treatingDoctorReducer,
    noteReducer
});

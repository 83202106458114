import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { Requirement } from "../../pages/studies/RequirementImaging";
import { getImageRequirementsApi, getLabRequirementsApi } from "../../helpers";
import { requirementApiResponseError, requirementApiResponseSuccess, RequirementsActionType } from "./requirementTypes";



/**
 * Get  by Lab requirement
 * @param {*} payload - 
 */
function* getRequirementLab(): SagaIterator {
  try {
    const requirements: Requirement[] = yield call(getLabRequirementsApi);
    yield put(requirementApiResponseSuccess(RequirementsActionType.LIST_LAB_REQUIREMENTS, requirements));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(requirementApiResponseError(RequirementsActionType.LIST_LAB_REQUIREMENTS, errorMessage));
  }
}


export function* watchGetRequirementsLab() {
  yield takeEvery(RequirementsActionType.LIST_LAB_REQUIREMENTS, getRequirementLab);
}

/**
 * Get  by Image requirement
 * @param {*} payload - 
 */
function* getRequirementImage(): SagaIterator {
  try {
    const requirements: Requirement[] = yield call(getImageRequirementsApi);
    yield put(requirementApiResponseSuccess(RequirementsActionType.LIST_IMAGE_REQUIREMENTS, requirements));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(requirementApiResponseError(RequirementsActionType.LIST_IMAGE_REQUIREMENTS, errorMessage));
  }
}


export function* watchGetRequirementsImage() {
  yield takeEvery(RequirementsActionType.LIST_IMAGE_REQUIREMENTS, getRequirementImage);
}




function* requirementSaga() {
  yield all([
    fork(watchGetRequirementsLab),
    fork(watchGetRequirementsImage),
 
  ]);
}

export default requirementSaga;

// constants
import { DoctorsActionsType, DoctorsActionTypes, DoctorState } from './treatingDoctorTypes';

const INIT_STATE: DoctorState = {
    loading: false,
    doctors: [],
    error: '',
    doctor: {
        id: 0,
        linkSignature: '',
        digitalStamp: ''
    }
};


const treatingDoctorReducer = (state: DoctorState = INIT_STATE, action: DoctorsActionsType): any => {
    switch (action.type) {
        case DoctorsActionTypes.API_RESPONSE_SUCCESS:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {

                case DoctorsActionTypes.UPDATE_DIGITAL_SIGN: {
                    return {
                        ...state,
                        doctor : action.payload.data,
                        loading: false,
                        modal: false,
                        error: ''
                    };
                }

                case DoctorsActionTypes.UPDATE_DIGITAL_STAMP: {
                    return {
                        ...state,
                        doctor : action.payload.data,
                        loading: false,
                        modal: false,
                        error: ''
                    };
                }

                case DoctorsActionTypes.GET_TREATING_DOCTOR_BY_USER: {
                    return {
                        ...state,
                        doctor: action.payload.data,
                        loading: false,
                        error: ''
                    };
                }
                
                default:
                    return { ...state };
            }

        case DoctorsActionTypes.API_RESPONSE_ERROR:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case DoctorsActionTypes.UPDATE_DIGITAL_STAMP:
                case DoctorsActionTypes.GET_TREATING_DOCTOR_BY_USER:
                case DoctorsActionTypes.UPDATE_DIGITAL_SIGN: {
                    return {
                        ...state,
                        doctors: [],
                        loading: false,
                        error: action.payload.data
                    };
                }

           
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default treatingDoctorReducer;
import { MedicalNotesActionType, MedicalNotesActionTypes, MedicalNoteState } from "./noteTypes";


const INIT_STATE : MedicalNoteState = {
    loading: false,
    medicalNote :{
        id: 0,
        description: "",
        filePath: "",
        creationDate: "",
        treatingDoctor: {
            id: 0,
            digitalStamp: "",
            linkSignature: ""
        },
        patient: {
            name: ""
        },
        treatment: {
            id: 0,
            description: ""
        }
    },
    patients:[],
    medicalNotes:[],
    modal : false,
};




const noteReducer = (state: MedicalNoteState = INIT_STATE, action: MedicalNotesActionType): any => {
   
    switch (action.type) {
        case MedicalNotesActionTypes.API_RESPONSE_SUCCESS:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case MedicalNotesActionTypes.SAVE_NOTE: {
                    return {
                        ...state,
                        medicalNote: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case MedicalNotesActionTypes.UPDATE_NOTE: {
                    return {
                        ...state,
                        medicalNote: action.payload.data,
                        modal: false,
                        loading: false,
                        error:''
                    };
                }
                case MedicalNotesActionTypes.GET_NOTES: {
                    return {
                        ...state,
                        medicalNotes: action.payload.data,
                        loading: false,
                    };
                }

                case MedicalNotesActionTypes.GET_PATIENTS: {
                    return {
                        ...state,
                        patients: action.payload.data,
                        loading: false,
                    };
                }

                case MedicalNotesActionTypes.DOWNLOAD_NOTE: {
                    return {
                        ...state,
                        patients: action.payload.data,
                        loading: false,
                    };
                } 
                case MedicalNotesActionTypes.OPEN_NOTE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:true,
                        error:''
                    };
                }
                case MedicalNotesActionTypes.CLOSE_NOTE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                    };
                }

                default:
                    return { 
                       
                        ...state 
                    };
            }

        case MedicalNotesActionTypes.API_RESPONSE_ERROR:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case MedicalNotesActionTypes.SAVE_NOTE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case MedicalNotesActionTypes.UPDATE_NOTE: {
                    return {
                        ...state,
                        medicalNote: action.payload.data,
                        loading: false,
                    };
                }
                case MedicalNotesActionTypes.GET_NOTES: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                case MedicalNotesActionTypes.GET_PATIENTS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
              
                default:
                    return { ...state };
            }
    
        default:
            return { ...state };
    }
};

export default noteReducer;

import { Folio } from "../../types/Folio";


export enum FoliosActionTypes {
    API_RESPONSE_SUCCESS = '@@folios/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@folios/API_RESPONSE_ERROR',
    GET_FOLIO_BY_ID = '@@folios/GET_FOLIO_BY_ID',
    GET_FILE = '@@folios/GET_FILE',
    APPROVE_FOLIO ='@@folios/APPROVE_FOLIO',
    REJECT_FOLIO ='@@folios/REJECT_FOLIO',
    CLOSE_UPLOAD_FOLIO ='@@folios/CLOSE_UPLOAD_FOLIO',
    CREATE_FOLIO = '@@folios/CREATE_FOLIO',
    OPEN_FOLIO_MODAL = '@@folios/OPEN_FOLIO_MODAL',
    CLOSE_FOLIO_MODAL = '@@folios/CLOSE_FOLIO_MODAL',
}

export interface FoliosActionsType {
    type:
      | FoliosActionTypes.API_RESPONSE_SUCCESS
      | FoliosActionTypes.API_RESPONSE_ERROR
      | FoliosActionTypes.CREATE_FOLIO
      | FoliosActionTypes.GET_FOLIO_BY_ID
      | FoliosActionTypes.GET_FILE
      | FoliosActionTypes.OPEN_FOLIO_MODAL
      | FoliosActionTypes.CLOSE_FOLIO_MODAL
      | FoliosActionTypes.CLOSE_UPLOAD_FOLIO
      | FoliosActionTypes.APPROVE_FOLIO
      | FoliosActionTypes.REJECT_FOLIO
      payload?: {
        actionType?: string;
        data?: FolioData | {};
        error?: string;
    };
  }
  
  interface FolioData {
    orderNumber: string;
    brand: string;
    model: string;
    quantity: number;
    characteristic: string;
    price: number;
    total: number;
  }
  
  // common success
  export const folioApiResponseSuccess = (
    actionType: string,
    data: FolioData | {}
  ): FoliosActionsType => ({
    type: FoliosActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
  });
  // common error
  export const folioApiResponseError = (
    actionType: string,
    error: string
  ): FoliosActionsType => ({
    type: FoliosActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
  });
  


export interface FolioState {
    loading: boolean;
    path : string;
    folios: Folio[];
    modal: boolean;
}



interface FolioData {
    id: number;
    orderNumber: string;
    provider :number;
}

interface FolioActionsType {
    type:
    | FoliosActionTypes.API_RESPONSE_SUCCESS
    | FoliosActionTypes.API_RESPONSE_ERROR
    | FoliosActionTypes.CREATE_FOLIO
    | FoliosActionTypes.GET_FOLIO_BY_ID
    | FoliosActionTypes.GET_FILE
    | FoliosActionTypes.OPEN_FOLIO_MODAL
    | FoliosActionTypes.CLOSE_FOLIO_MODAL
    payload: {
        actionType?: string;
        data?: FolioData | {};
        error?: string;
    };
}

interface State {
    folio?: FolioData | {};
    loading?: boolean;
    value?: boolean;
}

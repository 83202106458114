import { Hospital } from "../../types/Hospital";

export enum HospitalActionTypes {
  API_RESPONSE_SUCCESS = '@@hospitals/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@hospitals/API_RESPONSE_ERROR',
  SAVE_HOSPITAL = '@@hospitals/SAVE_HOSPITAL',
  UPDATE_HOSPITAL = '@@hospitals/UPDATE_HOSPITAL',
  GET_HOSPITALS = '@@hospitals/GET_HOSPITALS',
  DELETE_HOSPITAL = '@@hospitals/DELETE_HOSPITAL',
  OPEN_MODAL = '@@hospitals/OPEN_MODAL',
  CLOSE_MODAL = '@@hospitals/CLOSE_MODAL',
}

export interface HospitalActionsType {
  type:
  | HospitalActionTypes.API_RESPONSE_SUCCESS
  | HospitalActionTypes.API_RESPONSE_ERROR
  | HospitalActionTypes.SAVE_HOSPITAL
  | HospitalActionTypes.UPDATE_HOSPITAL
  | HospitalActionTypes.DELETE_HOSPITAL
  | HospitalActionTypes.OPEN_MODAL
  | HospitalActionTypes.GET_HOSPITALS
  | HospitalActionTypes.CLOSE_MODAL
  payload?: {
    actionType?: string;
    data?: Hospital | Hospital[] | {};
    error?: string;
  };
}

export interface AddHospitalPayload {
  data: {
    name: string;
    rfc: string;
    businessName: string;
    addressStreet: string;
    addressSuburb: string;
    addressMayoralty : string;
    addressPostalCode : number;
    addressState : string;
    branch: string;
    file:  File | null;
  }
}


export interface UpdateHospitalPayload {
  data: {
    id: number;
    name: string;
    rfc: string;
    businessName: string;
    addressStreet: string;
    addressSuburb: string;
    addressMayoralty : string;
    addressPostalCode : number;
    addressState : string;
    branch: string;
    file:  File | null;
  }
}

// common success
export const hospitalApiResponseSuccess = (
  actionType: string,
  data: Hospital | {}
): HospitalActionsType => ({
  type: HospitalActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const hospitalApiResponseError = (
  actionType: string,
  error: string
): HospitalActionsType => ({
  type: HospitalActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});


export interface HospitalState {
  loading: boolean;
  hospitals: Hospital[];
  modal: boolean;
  error: string | null;
  deleteStatus: string;
}

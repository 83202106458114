// constants
import { AreasActionsType, AreasActionTypes, AreaState } from './areaTypes';

enum STATUS {
    SUCCESS = "SUCCESS",
    FAIL = "FAIL"
  }

const INIT_STATE: AreaState = {
  loading: false,
  areas: [],
  modal: false,
  error:'',
  deleteStatus: ''
};


const areaReducer = (state: AreaState = INIT_STATE, action: AreasActionsType): any => {
    switch (action.type) {
        case AreasActionTypes.API_RESPONSE_SUCCESS:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case AreasActionTypes.SAVE_AREA: {
                    return {
                        ...state,
                        areas: [],
                        loading: false,
                        modal: false,
                        error: '',
                        deleteStatus:''
                    };
                }
                case AreasActionTypes.UPDATE_AREA: {
                    return {
                        ...state,
                        areas: [],
                        loading: false,
                        modal: false,
                        error: '',
                        deleteStatus:''
                    };
                }
                case AreasActionTypes.GET_AREAS: {
                    return {
                        ...state,
                        areas: action.payload.data,
                        loading: false,
                        deleteStatus:''
                    };
                }
                case AreasActionTypes.DELETE_AREA: {
                    return {
                        ...state,
                        areas: [],
                        loading: false,
                        deleteStatus: STATUS.SUCCESS
                    };
                }
                case AreasActionTypes.OPEN_MODAL: {
                    return {
                        ...state,
                        loading: false,
                        modal: true,
                        error: '',
                        deleteStatus:''
                    };
                }
                case AreasActionTypes.CLOSE_MODAL: {
                    return {
                        ...state,
                        loading: false,
                        modal: false,
                        error: '',
                        deleteStatus:''
                    };
                }
                default:
                    return { ...state };
            }

        case AreasActionTypes.API_RESPONSE_ERROR:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case AreasActionTypes.SAVE_AREA: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        deleteStatus:''
                    };
                }
                case AreasActionTypes.UPDATE_AREA: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        deleteStatus:''
                    };
                }
                case AreasActionTypes.GET_AREAS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        deleteStatus:''
                    };
                }
                case AreasActionTypes.DELETE_AREA: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        deleteStatus: STATUS.FAIL
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default areaReducer;
import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  saveAreaApi,
  updateAreaApi,
  getAreasApi,
  deleteAreaApi
} from "../../helpers";
import { AddAreaPayload, areaApiResponseError, areaApiResponseSuccess, AreasActionTypes, UpdateAreaPayload } from "./areaTypes";
import { Area } from "../../types/Area";


/**
 * Get  Areas
 * @param {*} payload - 
 */
function* getAreas(): SagaIterator {
  try {
    const areas: Area[] = yield call(getAreasApi);
    yield put(areaApiResponseSuccess(AreasActionTypes.GET_AREAS, areas));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(areaApiResponseError(AreasActionTypes.GET_AREAS, errorMessage));
  }
}

/**
 * Create Area
 * @param {*} payload - 
 */
function* createArea(action: { type: string; payload: AddAreaPayload }): SagaIterator {
  try {
    const area = yield call(saveAreaApi, action.payload );
    yield put(areaApiResponseSuccess(AreasActionTypes.SAVE_AREA, area));
    yield call(getAreas);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(areaApiResponseError(AreasActionTypes.SAVE_AREA, errorMessage));
  }
}

/**
 * Update Area
 * @param {*} payload - 
 */
function* updateArea(action: { type: string; payload: UpdateAreaPayload }): SagaIterator {
  try {
    const area = yield call(updateAreaApi, action.payload);
    yield put(areaApiResponseSuccess(AreasActionTypes.UPDATE_AREA, area));
    yield call(getAreas);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(areaApiResponseError(AreasActionTypes.UPDATE_AREA, errorMessage));
  }
}

/**
 * Delete
 * @param {*} payload - 
 */
function* deleteArea(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {   
    yield call(deleteAreaApi, action.payload.data.id);
    yield put(areaApiResponseSuccess(AreasActionTypes.DELETE_AREA, action.payload.data.id));
    yield call(getAreas);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(areaApiResponseError(AreasActionTypes.DELETE_AREA, errorMessage));
  }
}

function* openModalArea(): SagaIterator{
    yield put(areaApiResponseSuccess(AreasActionTypes.OPEN_MODAL,""));
}

function* closeModalArea(): SagaIterator{
    yield put(areaApiResponseSuccess(AreasActionTypes.CLOSE_MODAL,""));
}

export function* watchOpenModalArea() {
  yield takeEvery(AreasActionTypes.OPEN_MODAL,openModalArea);
}
export function* watchCloseModalArea() {
  yield takeEvery(AreasActionTypes.CLOSE_MODAL,closeModalArea);
}
export function* watchGetAreas() {
  yield takeEvery(AreasActionTypes.GET_AREAS, getAreas);
}
export function* watchCreateArea() {
  yield takeEvery(AreasActionTypes.SAVE_AREA, createArea);
}

export function* watchUpdateArea() {
  yield takeEvery(AreasActionTypes.UPDATE_AREA, updateArea);
}

export function* watchDeleteArea() {
  yield takeEvery(AreasActionTypes.DELETE_AREA, deleteArea);
}



function* areaSaga() {
  yield all([
    fork(watchCloseModalArea),
    fork(watchOpenModalArea),
    fork(watchCreateArea),
    fork(watchUpdateArea),
    fork(watchGetAreas),
    fork(watchDeleteArea)
  ]);
}

export default areaSaga;
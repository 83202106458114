import { ProviderActionType, ProviderActionTypes } from "./providerTypes";


export const getProviders = (): ProviderActionType => ({
  type: ProviderActionTypes.GET_PROVIDERS,
});

export const saveProvider = (
  enterprise: number,
  degree: string,
  position: string,
  professionalLicense: boolean | null | undefined,
  officePhone: string | null | undefined,
  officeExtension: string | null | undefined,
  companyLink: string,
  email: string,
  password: string,
  name: string,
  surname: string,
  lastname: string,
  optionalEmail: string | null | undefined,
  localPhone: string | null | undefined,
  localExtension: string | null | undefined,
  directPhone: string | null | undefined,
  directExtension: string | null | undefined,
  cellPhone: string,
  optionalCellPhone: string | null | undefined,
  avatarLink: string | null | undefined,
  role: number,
  hospital: number,
  area: number,
): ProviderActionType => ({
  type: ProviderActionTypes.SAVE_PROVIDER,
  payload: {
    data: {
      enterprise,
      degree,
      position,
      professionalLicense,
      officePhone,
      officeExtension,
      companyLink,
      email,
      password,
      name,
      surname,
      lastname,
      optionalEmail,
      localPhone,
      localExtension,
      directPhone,
      directExtension,
      cellPhone,
      optionalCellPhone,
      avatarLink,
      role,
      hospital,
      area,
    }
  },
});

export const updateProvider = (
  id: number | null | undefined,
  enterprise: number,
  degree: string,
  position: string,
  professionalLicense: boolean | null | undefined,
  officePhone: string | null | undefined,
  officeExtension: string | null | undefined,
  companyLink: string,
  idUser: number | null | undefined,
  email: string,
  password: string,
  name: string,
  surname: string,
  lastname: string,
  optionalEmail: string | null | undefined,
  localPhone: string | null | undefined,
  localExtension: string | null | undefined,
  directPhone: string | null | undefined,
  directExtension: string | null | undefined,
  cellPhone: string,
  optionalCellPhone: string | null | undefined,
  avatarLink: string | null | undefined,
  role: number,
  hospital: number,
  area: number,
): ProviderActionType => ({
  type: ProviderActionTypes.UPDATE_PROVIDER,
  payload: {
    data: {
      id,
      enterprise,
      degree,
      position,
      professionalLicense,
      officePhone,
      officeExtension,
      companyLink,
      idUser,
      email,
      password,
      name,
      surname,
      lastname,
      optionalEmail,
      localPhone,
      localExtension,
      directPhone,
      directExtension,
      cellPhone,
      optionalCellPhone,
      avatarLink,
      role,
      hospital,
      area,
    }
  },
});


export const getProvidersByUser = (id: number): ProviderActionType =>
  ({ type: ProviderActionTypes.GET_PROVIDER_BY_USER, payload: { data: { id } } });


export const getProvidersByEnterprise = (id: number): ProviderActionType  => ({
  type: ProviderActionTypes.GET_PROVIDER_BY_ENTERPRISE, payload: { data: {id } }
});

export const deleteProvider = (id: number): ProviderActionType => ({
  type: ProviderActionTypes.DELETE_PROVIDER,
  payload: {
    data: {
      id
    }
  }
});

export const openModalProvider = () => {
  return { type: ProviderActionTypes.OPEN_MODAL }
}
export const closeModalProvider = () => {
  return { type: ProviderActionTypes.CLOSE_MODAL }
}
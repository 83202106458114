import { FoliosActionsType, FoliosActionTypes, FolioState } from "./folioTypes";



const INIT_STATE : FolioState = {
    path : "",
    loading: false,
    folios:[],
    modal : false,
};

const folioReducer = (state: FolioState = INIT_STATE, action: FoliosActionsType): any => {
   
    switch (action.type) {
        case FoliosActionTypes.API_RESPONSE_SUCCESS:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case FoliosActionTypes.CREATE_FOLIO: {
                    return {
                        ...state, 
                        folio : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case FoliosActionTypes.GET_FILE: {
                    return {
                        ...state, 
                        folio : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case FoliosActionTypes.GET_FOLIO_BY_ID: {
                    return {
                        ...state, 
                        folios : action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case FoliosActionTypes.APPROVE_FOLIO: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case FoliosActionTypes.REJECT_FOLIO: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case FoliosActionTypes.CLOSE_UPLOAD_FOLIO: {
                    return {
                        ...state, 
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
                case FoliosActionTypes.OPEN_FOLIO_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:true,
                        error:''
                    };
                }
                case FoliosActionTypes.CLOSE_FOLIO_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                        error:''
                    };
                }
                default:
                    return { ...state };
            }

        case FoliosActionTypes.API_RESPONSE_ERROR:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case FoliosActionTypes.CREATE_FOLIO: {
                    return {
                        ...state, 

                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }
                case FoliosActionTypes.GET_FILE: {
                    return {
                        ...state, 
                        folio : action.payload.data,
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }
                case FoliosActionTypes.GET_FOLIO_BY_ID: {
                    return {
                        ...state, 
                        folios : action.payload.data,
                        loading: false,
                        modal: false,
                        error: action.payload.error
                    };
                }

                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default folioReducer;

import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { getAttendanceByProviderApi, getNotAttendanceApi, updateAttendanceApi } from "../../helpers";
import { attendanceApiResponseError, attendanceApiResponseSuccess, ProviderAttendanceActionTypes } from "./attendanceTypes";
import { PayloadUpdateAttendance } from "../../types/Attendance";


/**
 * Update Attendance
 * @param {*} payload - 
 */
function* updateAttendance(action: { type: string; payload: { data : { updateAttendance:PayloadUpdateAttendance } } }): SagaIterator {
  try {
    const response = yield call(updateAttendanceApi, action.payload.data.updateAttendance);
    const resData = response.data;
   // yield put(getAttendanceByPatient(id));
    yield put(attendanceApiResponseSuccess(ProviderAttendanceActionTypes.UPDATE_ATTENDANCE, resData));
   
  } catch (error: any) {
    yield put(attendanceApiResponseError(ProviderAttendanceActionTypes.UPDATE_ATTENDANCE, error));
  }
}


/**
 * Get Providers
 * @param {*} payload - 
 */
function* getNotAttendance(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {
    const response = yield call(getNotAttendanceApi, action.payload.data.id);
    const data = response.data;
    yield put(attendanceApiResponseSuccess(ProviderAttendanceActionTypes.GET_NOT_ATTENDANCE, data));
  } catch (error: any) {
    yield put(attendanceApiResponseError(ProviderAttendanceActionTypes.GET_NOT_ATTENDANCE, error));
  }
}


/**
 * Get Providers
 * @param {*} payload - 
 */
function* getAttendanceByProvider(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {
    const response = yield call(getAttendanceByProviderApi, action.payload.data.id);
    const data = response.data;
    yield put(attendanceApiResponseSuccess(ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PROVIDER, data));
  } catch (error: any) {
    yield put(attendanceApiResponseError(ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PROVIDER, error));
  }
}

export function* watchUpdateAttendance() {
  yield takeEvery(ProviderAttendanceActionTypes.UPDATE_ATTENDANCE, updateAttendance);
}

export function* watchNotAttendance() {
  yield takeEvery(ProviderAttendanceActionTypes.GET_NOT_ATTENDANCE, getNotAttendance);
}


export function* watchGetAttendanceByProvider() {
  yield takeEvery(ProviderAttendanceActionTypes.GET_ATTENDANCE_BY_PROVIDER, getAttendanceByProvider);
}



function* resetState(): SagaIterator {
  yield put(attendanceApiResponseSuccess(ProviderAttendanceActionTypes.RESET_STATE, ''));
}
export function* watchResetState() {
  yield takeEvery(ProviderAttendanceActionTypes.RESET_STATE, resetState);
}


function* attendanceSaga() {
  yield all([
    fork(watchUpdateAttendance),
    fork(watchGetAttendanceByProvider),
    fork(watchNotAttendance),
    fork(watchResetState),
  ]);
}

export default attendanceSaga;

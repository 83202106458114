import { StudiesActionTypes, StudyActionTypes, StudyState } from "./studyTypes";


const INIT_STATE: StudyState = {
    loading: false,
    studies:[],
    modal : false,
    studyFiles: [],
    error: ''
};

const studyReducer = (state: StudyState = INIT_STATE, action: StudyActionTypes): any => {
   
    switch (action.type) {
        case StudiesActionTypes.API_RESPONSE_SUCCESS:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case StudiesActionTypes.LIST_STUDIES: {
                    return {
                        ...state,
                        studies: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }

                case StudiesActionTypes.LIST_FILE_STUDIES: {
                    return {
                        ...state,
                        studyFiles: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }

                case StudiesActionTypes.CLOSE_FILE_UPLOAD: {
                    return {
                        ...state,
                        studyFile: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }


                case StudiesActionTypes.DELETE_FILE_STUDY_IMAGE: {
                    return {
                        ...state,
                        studyFile: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }

                case StudiesActionTypes.DELETE_FILE_STUDY_LAB: {
                    return {
                        ...state,
                        studyFile: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }

       
                case StudiesActionTypes.GET_IMAGE_FILE_STUDY: {
                    return {
                        ...state,
                        studyFile: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }

                case StudiesActionTypes.GET_LAB_FILE_STUDY: {
                    return {
                        ...state,
                        studyFile: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }

                case StudiesActionTypes.UPDATE_STATUS_STUDY: {
                    return {
                        ...state,
                        studyFile: action.payload.data,
                        loading: false,
                        modal: false,
                        error:''
                    };
                }
 
                case StudiesActionTypes.OPEN_MODAL: {
                    return {
                        ...state,
                        modal: true,
                        loading: false,
                    };
                }
                case StudiesActionTypes.CLOSE_MODAL:{
                    return {
                        ...state,
                        loading: false,
                        modal:false,
                        error:''
                    };
                }
                default:
                    return { ...state };
            }

        case StudiesActionTypes.API_RESPONSE_ERROR:
            if (!action.payload) return { ...state }; 
            switch (action.payload.actionType) {
                case StudiesActionTypes.LIST_STUDIES: 
                case StudiesActionTypes.LIST_FILE_STUDIES:
                case StudiesActionTypes.CLOSE_FILE_UPLOAD: 
                case StudiesActionTypes.DELETE_FILE_STUDY_LAB:
                    case StudiesActionTypes.DELETE_FILE_STUDY_IMAGE:
                case StudiesActionTypes.GET_IMAGE_FILE_STUDY:   
                case StudiesActionTypes.GET_LAB_FILE_STUDY:
                case StudiesActionTypes.UPDATE_STATUS_STUDY:
                {
                    return {
                        ...state,
                        loading: false,
                        modal: false,
                        error:action.payload.error
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default studyReducer;

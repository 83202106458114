

import { AddUserPayload, UpdateUserPayload } from "../../redux/users/userTypes";
import { User } from "../../types/User";
import { APICore } from "./apiCore";

const api = new APICore();
const baseUrl = "/users";


export const saveUserApi = async (user: AddUserPayload):Promise<User> => {
  try {
    const response = await api.create(`${baseUrl}`, user.data);;
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const updateUserApi = async (user: UpdateUserPayload):Promise<User> => {
  try {
    const response = await api.update(`${baseUrl}`, user.data);;
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const getUsersApi = async ():Promise<User[]> => {
  try {
    const response = await api.get(`${baseUrl}/all`, '');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const getUsersByHospitalApi = async ():Promise<User[]> => {
  try {
    const response = await api.get(`${baseUrl}/hospital`, '');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const deleteUserApi = async ( id: number): Promise<Boolean> => {
  try {
    const response = await api.delete(`${baseUrl}/${id}`);
    if (response.status === 200) {
      return true; 
    } else {
      throw new Error('Error al eliminar el hospital');
    }
  } catch (error: any) {
    if (error.response) {
      const errorMessage = error.response?.data?.message || 'Error de servidor';
      throw new Error(errorMessage);
    } else {
      throw new Error('Error de conexión');
    }
  }
}

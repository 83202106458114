interface LayoutState {
    layoutType: string | null,
    layoutWidth: string | null,
    menuPosition: string | null,
    leftSideBarTheme: string | null,
    leftSideBarType: string | null,
    showSidebarUserInfo: boolean,
    topbarTheme: string | null,
    isOpenRightSideBar: boolean,
  }
  
  // Define la interfaz para el estado de la aplicación
  interface AppState {
    Layout: LayoutState;
  }
  
  let lastLayoutState: LayoutState | null = null;
  let lastResult: LayoutState | null = null;
  
  export const selectLayoutData = (state: AppState): LayoutState => {
    const layoutState = state.Layout;
  
    // Compara si el estado de Layout es el mismo que antes
    if (lastLayoutState === layoutState) {
      // Devuelve el resultado memoizado si no ha habido cambios
      return lastResult as LayoutState;
    }
  
    // Actualiza lastLayoutState y lastResult solo si hay cambios
    lastLayoutState = layoutState;
    lastResult = {
    layoutType: layoutState.layoutType,
    layoutWidth: layoutState.layoutWidth,
    menuPosition: layoutState.menuPosition,
    leftSideBarTheme: layoutState.leftSideBarTheme,
    leftSideBarType: layoutState.leftSideBarType,
    showSidebarUserInfo: layoutState.showSidebarUserInfo,
    topbarTheme: layoutState.topbarTheme,
    isOpenRightSideBar: layoutState.isOpenRightSideBar,
    };
  
    return lastResult;
  };
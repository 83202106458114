

import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  saveHospitalApi,
  getHospitalsApi,
  updateHospitalApi,
  deleteHospitalApi
} from "../../helpers/";


// constants
import { AddHospitalPayload, HospitalActionTypes, hospitalApiResponseError, hospitalApiResponseSuccess, UpdateHospitalPayload } from "./hospitalTypes";
import { Hospital } from "../../types/Hospital";



/**
 * Get Hospitals
 * @param {*} payload - 
 */
function* getHospitals(): SagaIterator {
  try {
    const hospitals: Hospital[] = yield call(getHospitalsApi);
    yield put(hospitalApiResponseSuccess(HospitalActionTypes.GET_HOSPITALS, hospitals));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(hospitalApiResponseError(HospitalActionTypes.GET_HOSPITALS, errorMessage));
  }
}

/**
 * Create Hospital
 * @param {*} payload - 
 */
function* createHospital(action: { type: string; payload: AddHospitalPayload }): SagaIterator {
  try {
    const hospital = yield call(saveHospitalApi, action.payload);
    yield put(hospitalApiResponseSuccess(HospitalActionTypes.SAVE_HOSPITAL, hospital));
    yield call(getHospitals);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(hospitalApiResponseError(HospitalActionTypes.SAVE_HOSPITAL, errorMessage));
  }
}

/**
 * Update Hospital
 * @param {*} payload - 
 */
function* updateHospital(action: { type: string; payload: UpdateHospitalPayload }): SagaIterator {
  try {
    const hospital = yield call(updateHospitalApi, action.payload);  
    yield put(hospitalApiResponseSuccess(HospitalActionTypes.UPDATE_HOSPITAL, hospital));
    yield call(getHospitals);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(hospitalApiResponseError(HospitalActionTypes.UPDATE_HOSPITAL, errorMessage));
  }
}

/**
 * Delete
 * @param {*} payload - 
 */
function* deleteHospital(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {   
    yield call(deleteHospitalApi, action.payload.data.id);
    yield put(hospitalApiResponseSuccess(HospitalActionTypes.DELETE_HOSPITAL, action.payload.data.id));
    yield call(getHospitals);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(hospitalApiResponseError(HospitalActionTypes.DELETE_HOSPITAL, errorMessage));
  }
}

function* openModalHospital(): SagaIterator{
    yield put(hospitalApiResponseSuccess(HospitalActionTypes.OPEN_MODAL,""));
}

function* closeModalHospital(): SagaIterator{
    yield put(hospitalApiResponseSuccess(HospitalActionTypes.CLOSE_MODAL,""));
 
}


export function* watchUpdateHospital() {
  yield takeEvery(HospitalActionTypes.UPDATE_HOSPITAL, updateHospital);
}

export function* watchDeleteHospital() {
  yield takeEvery(HospitalActionTypes.DELETE_HOSPITAL, deleteHospital);
}


export function* watchGetHospitals() {
  yield takeEvery(HospitalActionTypes.GET_HOSPITALS, getHospitals);
}

export function* watchCreateHospital() {
  yield takeEvery(HospitalActionTypes.SAVE_HOSPITAL, createHospital);
}


export function* watchOpenModalHospital() {
  yield takeEvery(HospitalActionTypes.OPEN_MODAL,openModalHospital);
}



export function* watchCloseModalHospital() {
  yield takeEvery(HospitalActionTypes.CLOSE_MODAL,closeModalHospital);
}


function* hospitalSaga() {
  yield all([
    fork(watchCloseModalHospital),
    fork(watchOpenModalHospital),
    fork(watchCreateHospital),
    fork(watchGetHospitals),
    fork(watchUpdateHospital),
  ]);
}

export default hospitalSaga;

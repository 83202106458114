
import { Patient } from "../../types/Patient";
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/patients";


export const getAllPatientsApi = async () : Promise<Patient[]>=> {
  try {
    const response = await api.get(`${baseUrl}/all`, '');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const getPatientsByIdApi = async (id: number) : Promise<Patient>=> {
  try {
    const response = await api.get(`${baseUrl}/${id}`,'');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const updateDischargedPatientApi = async ( id: number): Promise<Boolean> => {
  try {
    const response = await api.get(`${baseUrl}/discharged/${id}`, '');
    if (response.status === 200) {
      return true; 
    } else {
      throw new Error('Error al dar de alta al paciente.');
    }
  } catch (error: any) {
    if (error.response) {
      const errorMessage = error.response?.data?.message || 'Error de servidor';
      throw new Error(errorMessage);
    } else {
      throw new Error('Error de conexión');
    }
  }
}

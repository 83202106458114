
import { AddAreaPayload, UpdateAreaPayload } from "../../redux/areas/areaTypes";
import { Area } from "../../types/Area";
import { APICore } from "./apiCore";

const api = new APICore();
const baseUrl = "/areas";


export const saveAreaApi = async (area: AddAreaPayload): Promise<Area> => {
  try {
    const response = await api.create(`${baseUrl}`, area.data);;
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
};

export const updateAreaApi = async (areaPayload: UpdateAreaPayload): Promise<Area | {}> => {
  try {
    const response = await api.update(`${baseUrl}`, areaPayload.data);
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
};


export const getAreasApi = async (): Promise<Area[]> => {
  try {
    const response = await api.get(`${baseUrl}/all`, '');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const deleteAreaApi = async (id: number): Promise<Boolean> => {
  try {
    const response = await api.delete(`${baseUrl}/${id}`);
    if (response.status === 200) {
      return true; // Eliminación exitosa
    } else {
      throw new Error('Error al eliminar el área');
    }
  } catch (error: any) {
    if (error.response) {
      const errorMessage = error.response?.data?.message || 'Error de servidor';
      throw new Error(errorMessage);
    } else {
      throw new Error('Error de conexión');
    }
  }

}


import { Enterprise } from "../../types/Enterprise";

export enum EnterprisesActionTypes {
  API_RESPONSE_SUCCESS = '@@enterprises/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@enterprises/API_RESPONSE_ERROR',
  GET_ALL_ENTERPRISE = '@@enterprises/GET_ALL_ENTERPRISE',
  GET_FILE = '@@enterprises/GET_FILE',
  CREATE_ENTERPRISE = '@@enterprises/CREATE_ENTERPRISE',
  DELETE_ENTERPRISE = '@@enterprises/DELETE_ENTERPRISE',
  UPDATE_ENTERPRISE = '@@enterprises/UPDATE_ENTERPRISE',
  OPEN_ENTERPRISE_MODAL = '@@enterprises/OPEN_ENTERPRISE_MODAL',
  CLOSE_ENTERPRISE_MODAL = '@@enterprises/CLOSE_ENTERPRISE_MODAL',
}


export interface EnterprisesActionsType {
  type:
    | EnterprisesActionTypes.API_RESPONSE_SUCCESS
    | EnterprisesActionTypes.API_RESPONSE_ERROR
    | EnterprisesActionTypes.CREATE_ENTERPRISE
    | EnterprisesActionTypes.UPDATE_ENTERPRISE
    | EnterprisesActionTypes.GET_ALL_ENTERPRISE
    | EnterprisesActionTypes.GET_FILE
    | EnterprisesActionTypes.DELETE_ENTERPRISE
    | EnterprisesActionTypes.OPEN_ENTERPRISE_MODAL
    | EnterprisesActionTypes.CLOSE_ENTERPRISE_MODAL    
    payload?: {
      actionType?: string;
      data?: Enterprise | Enterprise[] | {};
      error?: string;
    };
}

interface EnterpriseData {
  name: string;
  file:  File | null;
}

export interface AddEnterprisePayload {
  data: {
    name: string;
    file:  File | null;
  }
}

export interface UpdateEnterprisePayload {
  data: {
    id: number;
    name: string;
    file:  File | null;
  }
}

export interface EnterpriseState {
  loading: boolean;
  enterprises: Enterprise[];
  modal: boolean;
  error: string | null;
  deleteStatus: string;
  url: string;
}

// common success
export const enterpriseApiResponseSuccess = (
  actionType: string,
  data: EnterpriseData | {}
): EnterprisesActionsType => ({
  type: EnterprisesActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const enterpriseApiResponseError = (
  actionType: string,
  error: string
): EnterprisesActionsType => ({
  type: EnterprisesActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});
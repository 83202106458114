
import { AddHospitalPayload, UpdateHospitalPayload } from "../../redux/hospitals/hospitalTypes";
import { Hospital } from "../../types/Hospital";
import { APICore } from "./apiCore";

const api = new APICore();
const baseUrl = "/hospitals";


export const saveHospitalApi = async (hospital: AddHospitalPayload):Promise<Hospital> => {
  try {
    const response = await api.createWithFile(`${baseUrl}`, hospital.data);;
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const updateHospitalApi = async (hospital: UpdateHospitalPayload) => {
  try {
    const response = await api.updateWithFile(`${baseUrl}`, hospital.data);;
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const getHospitalsApi = async ():Promise<Hospital[]> => {
  try {
    const response = await api.get(`${baseUrl}/all`, '');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const deleteHospitalApi = async ( id: number): Promise<Boolean> => {
  try {
    const response = await api.delete(`${baseUrl}/${id}`);
    if (response.status === 200) {
      return true; 
    } else {
      throw new Error('Error al eliminar el hospital');
    }
  } catch (error: any) {
    if (error.response) {
      const errorMessage = error.response?.data?.message || 'Error de servidor';
      throw new Error(errorMessage);
    } else {
      throw new Error('Error de conexión');
    }
  }
}

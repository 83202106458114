import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { getTreatingDoctorByIdUserApi, updateDigitalSignApi, updateDigitalStampApi } from "../../helpers";
import { TreatingDoctor, UpdateTreatingDoctorPayload } from "../../types/TreatingDoctor";
import { DoctorsActionTypes, doctorsApiResponseError, doctorsApiResponseSuccess } from "./treatingDoctorTypes";




/**
 * Get  Treating doctor
 * @param {*} payload - 
 */
function* getTreatingDoctorByIdUser(action: { type: string; payload: { data : { id:number } } }): SagaIterator {
  try {
    const doctor: TreatingDoctor = yield call(getTreatingDoctorByIdUserApi,action.payload.data.id);
    yield put(doctorsApiResponseSuccess(DoctorsActionTypes.GET_TREATING_DOCTOR_BY_USER, doctor));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(doctorsApiResponseError(DoctorsActionTypes.GET_TREATING_DOCTOR_BY_USER, errorMessage));
  }
}



/**
 * UPDATE_DIGITAL_SIGN
 * @param {*} payload - 
 */
function* updateDigitalSign(action: { type: string; payload: UpdateTreatingDoctorPayload }): SagaIterator {
  try {   
    yield call(updateDigitalSignApi, action.payload);
    yield put(doctorsApiResponseSuccess(DoctorsActionTypes.UPDATE_DIGITAL_SIGN, action.payload.data.id));
  
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(doctorsApiResponseError(DoctorsActionTypes.UPDATE_DIGITAL_SIGN, errorMessage));
  }
}

/**
 * UPDATE_DIGITAL_STAMP
 * @param {*} payload - 
 */
function* updateDigitalStamp(action: { type: string; payload: UpdateTreatingDoctorPayload }): SagaIterator {
  try {   
    yield call(updateDigitalStampApi, action.payload);
    yield put(doctorsApiResponseSuccess(DoctorsActionTypes.UPDATE_DIGITAL_SIGN, action.payload.data.id));
  
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    yield put(doctorsApiResponseError(DoctorsActionTypes.UPDATE_DIGITAL_SIGN, errorMessage));
  }
}


export function* watchGetTreatingDoctorByUser() {
  yield takeEvery(DoctorsActionTypes.GET_TREATING_DOCTOR_BY_USER, getTreatingDoctorByIdUser);
}
export function* watchUpdateDigitalSign() {
  yield takeEvery(DoctorsActionTypes.UPDATE_DIGITAL_SIGN, updateDigitalSign);
}

export function* watchUpdateDigitalStamp() {
  yield takeEvery(DoctorsActionTypes.UPDATE_DIGITAL_STAMP, updateDigitalStamp);
}


function* treatingDoctorSaga() {
  yield all([
    fork(watchGetTreatingDoctorByUser),
    fork(watchUpdateDigitalSign),
    fork(watchUpdateDigitalStamp)
  ]);
}

export default treatingDoctorSaga;
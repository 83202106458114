import { PatientsActionsType, PatientsActionTypes } from "./patientTypes";

export const getPatients = (): PatientsActionsType => ({
  type: PatientsActionTypes.GET_PATIENTS,
});

export const updateDischargedPatient = (id: number): PatientsActionsType => ({
  type: PatientsActionTypes.UPDATE_DISCHARGED_PATIENT,
  payload: {
    data :{
      id
    }
  },
});

export const getPatientById = (id: number): PatientsActionsType => ({
  type: PatientsActionTypes.GET_PATIENT_BY_ID,
  payload: {
    data :{
      id
    }
  },
});



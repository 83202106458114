import { Navigate, useLocation } from 'react-router-dom';
import { APICore } from '../helpers/api/apiCore';

interface PrivateRouteProps {
    element: JSX.Element; // Cambiado a `element` para reflejar la API moderna de react-router-dom
    roles?: string[]; // Matriz de roles para la validación de permisos
}

/**
 * PrivateRoute obliga a la autorización antes de que se pueda acceder a la ruta
 */
const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, roles }) => {
    const api = new APICore();
    const location = useLocation();
    const isAuthenticated = api.isUserAuthenticated();
    // Verifica si el usuario está autenticado
   if (!isAuthenticated) {
        return (
            <Navigate
                to="/auth/login"
                state={{ from: location }} 
                replace
            />
        );
    }

    const loggedInUser = api.getLoggedInUser();

    // Verifica si el rol está autorizado
    if (roles && !roles.includes(loggedInUser.role.name)) {
        return <Navigate to="/" replace />;
    }

    // El usuario está autorizado, renderiza el elemento
    return element;
};

export default PrivateRoute;
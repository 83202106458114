
import { AddHospitalDirectoryPayload, UpdateHospitalDirectoryPayload } from "../../redux/directories/directoryTypes";
import { HospitalDirectory } from "../../types/HospitalDirectory";
import { APICore } from "./apiCore";


const api = new APICore();
const baseUrl = "/hospital-directory";



export const saveDirectoryApi = async (directory: AddHospitalDirectoryPayload):Promise<HospitalDirectory> => {
  try {
    const response = await api.create(`${baseUrl}`, directory.data);
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}

export const updateDirectoryApi = async (directory: UpdateHospitalDirectoryPayload): Promise<HospitalDirectory | {}> => {
  try {
    const response = await api.update(`${baseUrl}`, directory.data);;
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}


export const getDirectoriesApi = async () : Promise<HospitalDirectory[]>=> {
  try {
    const response = await api.get(`${baseUrl}/all`, '');
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : new Error('Error de conexión');
  }
}


export const deleteDirectoryApi = async ( id: number): Promise<Boolean> => {
  try {
    const response = await api.delete(`${baseUrl}/${id}`);
    if (response.status === 200) {
      return true; 
    } else {
      throw new Error('Error al eliminar el directorio.');
    }
  } catch (error: any) {
    if (error.response) {
      const errorMessage = error.response?.data?.message || 'Error de servidor';
      throw new Error(errorMessage);
    } else {
      throw new Error('Error de conexión');
    }
  }
}










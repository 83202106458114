import { Provider } from "../../types/Provider";

export enum ProviderActionTypes {
  API_RESPONSE_SUCCESS = '@@providers/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@providers/API_RESPONSE_ERROR',
  SAVE_PROVIDER = '@@providers/SAVE_PROVIDER',
  UPDATE_PROVIDER = '@@providers/UPDATE_PROVIDER',
  GET_PROVIDERS = '@@providers/GET_PROVIDERS',
  GET_PROVIDER_BY_USER = '@@providers/GET_PROVIDER_BY_USER',
  GET_PROVIDER_BY_ENTERPRISE = '@@providers/GET_PROVIDER_BY_ENTERPRISE',
  DELETE_PROVIDER = '@@providers/DELETE_PROVIDER',
  OPEN_MODAL = '@@providers/OPEN_MODAL',
  CLOSE_MODAL = '@@providers/CLOSE_MODAL',
}



export interface ProviderActionType {
  type:
    | ProviderActionTypes.API_RESPONSE_SUCCESS
    | ProviderActionTypes.API_RESPONSE_ERROR
    | ProviderActionTypes.SAVE_PROVIDER
    | ProviderActionTypes.UPDATE_PROVIDER
    | ProviderActionTypes.GET_PROVIDERS
    | ProviderActionTypes.DELETE_PROVIDER
    | ProviderActionTypes.GET_PROVIDER_BY_ENTERPRISE
    | ProviderActionTypes.GET_PROVIDER_BY_USER
       
    payload?: {
      actionType?: string;
      data?: Provider | Provider[] | {};
      error?: string;
    };
}

// common success
export const providerApiResponseSuccess = (
  actionType: string,
  data: Provider | {}
): ProviderActionType => ({
  type: ProviderActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const providerApiResponseError = (
  actionType: string,
  error: string
): ProviderActionType => ({
  type: ProviderActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});



export interface AddProviderPayload {
  data: {
    enterprise: number;
    degree : string;
    position : string;
    professionalLicense : boolean;
    officePhone : string;
    officeExtension : string;
    companyLink : string;
    user : {
      email: string;
      password: string;
      name: string;
      surname: string;
      lastname: string;
      optionalEmail: string | null | undefined;
      localPhone: string | null | undefined;
      localExtension: string | null | undefined;
      directPhone: string | null | undefined;
      directExtension: string | null | undefined;
      cellPhone: string | null | undefined;
      optionalCellPhone : string | null | undefined;
      avatarLink: string | null | undefined;
      role: number;
      hospital: number;
      area: number;
    }
  }
} 


export interface UpdateProviderPayload {
  data: {
    id: number;
    enterprise: number;
    degree : string;
    position : string;
    professionalLicense : boolean;
    officePhone : string;
    officeExtension : string;
    companyLink : string;
    user : {
      id : number;
      email: string;
      password: string;
      name: string;
      surname: string;
      lastname: string;
      optionalEmail: string | null | undefined;
      localPhone: string | null | undefined;
      localExtension: string | null | undefined;
      directPhone: string | null | undefined;
      directExtension: string | null | undefined;
      cellPhone: string | null | undefined;
      optionalCellPhone : string | null | undefined;
      avatarLink: string | null | undefined;
      role: number;
      hospital: number;
      area: number;
    }
  }
}

export interface ProvideState {
  loading: boolean;
  provider: {};
  providers: Provider[];
  modal: boolean;
  error: string | undefined;
  deleteStatus: string;
}


import { HospitalDirectory } from "../../types/HospitalDirectory";

export enum HospitalDirectoryActionType {
    API_RESPONSE_SUCCESS = '@@directories/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@directories/API_RESPONSE_ERROR',
    SAVE_DIRECTORY = '@@directories/SAVE_DIRECTORY',
    UPDATE_DIRECTORY = '@@directories/UPDATE_DIRECTORY',
    GET_DIRECTORIES = '@@directories/GET_DIRECTORIES',
    DELETE_DIRECTORY = '@@directories/DELETE_DIRECTORY',
    OPEN_MODAL = '@@directories/OPEN_MODAL',
    CLOSE_MODAL = '@@directories/CLOSE_MODAL',
}

export interface HospitalDirectoryActionsType {
    type:
    | HospitalDirectoryActionType.API_RESPONSE_SUCCESS
    | HospitalDirectoryActionType.API_RESPONSE_ERROR
    | HospitalDirectoryActionType.SAVE_DIRECTORY
    | HospitalDirectoryActionType.UPDATE_DIRECTORY
    | HospitalDirectoryActionType.GET_DIRECTORIES
    | HospitalDirectoryActionType.DELETE_DIRECTORY
    | HospitalDirectoryActionType.OPEN_MODAL
    | HospitalDirectoryActionType.CLOSE_MODAL
    payload?: {
      actionType?: string;
      data?: HospitalDirectory | HospitalDirectory[] | {};
      error?: string;
    };
  
  }
  
  export interface DeleteHospitalDirectoryAction {
    type: string;
    payload: number;
  }
  
  export interface AddHospitalDirectoryPayload {
    data: {
        name: string;
        email:string;
        surname: string;
        lastname: string;
        position: string;
        optionalEmail: string;
        localPhone: string;
        localExtension: string;
        directPhone: string;
        cellPhone: string;
        avatarLink:string;
        hospital:number;
    }

  }

  
  export interface UpdateHospitalDirectoryPayload {
    data: {
        id:number;
        name: string;
        email:string;
        surname: string;
        lastname: string;
        position: string;
        optionalEmail: string;
        localPhone: string;
        localExtension: string;
        directPhone: string;
        cellPhone: string;
        avatarLink:string;
        hospital:number;
    }
  }
  
  // common success
  export const directoryApiResponseSuccess = (
    actionType: string,
    data: HospitalDirectory | {}
  ): HospitalDirectoryActionsType => ({
    type: HospitalDirectoryActionType.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
  });
  
  // common error
  export const directoryApiResponseError = (
    actionType: string,
    error: string
  ): HospitalDirectoryActionsType => ({
    type: HospitalDirectoryActionType.API_RESPONSE_ERROR,
    payload: { actionType, error },
  });
  
  export interface HospitalDirectoryState {
    loading: boolean;
    hospitalDirectories: HospitalDirectory[];
    modal: boolean;
    error: string | null;
    deleteStatus: string;
  }